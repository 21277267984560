import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import SignOut from "../SignOut";
import { link, downloadFile, numb2 } from "../link.js";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Footer from "../footer";
import Loading from "../loading";
export class Financialstatements extends Component {
  constructor() {
    super();
    this.state = {
      filters: {
        datefrom: "",
        dateTo: "",
        userid: "",
        memberid: "",
        roundid: "",
        roundname: "",
        name: "",
        bank: "",
        termstartdate: "",
        termduedate: "",
        status: "",
      },
      rounds: [],
      reportType: "contr",
      reportResults: [],
      loading: false,
    };
    //this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    //this.handleChangeDate = this.handleChangeDate.bind(this);
    this.getReport = this.getReport.bind(this);
  }

  getReport = (reptype) => {
    var token = localStorage.getItem("token");
    var postData = JSON.stringify({
      datefrom: this.state.filters.datefrom,
      dateTo: this.state.filters.dateTo,
      userid: this.state.filters.userid,
      memberid: this.state.filters.memberid,
      roundid: this.state.filters.roundid,
      roundname: this.state.filters.roundname,
      name: this.state.filters.name,
      bank: this.state.filters.bank,
      termstartdate: this.state.filters.termstartdate,
      termduedate: this.state.filters.termduedate,
      status: this.state.filters.status,
    });
    //var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "income");
    axios
      .post(link() + `&do=get&ra=report&rec=finstat`, postData, {
        headers: headers, timeout: 30000
      })
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: ", res);

        this.setState({
          reportResults: res.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          loading: false,
        });
        console.log("AXIOS ERROR: ", err);

        if (err.code === 'ECONNABORTED') {
          alert("Server connection timed out");
      } else {
       // alert("Error occured");
      }
        this.setState({
          loading: false,
          isLoading: false,
        });
      });
  };
  getRounds = () => {
    var token = localStorage.getItem("token");
    //var rounds = [];
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    fetch(
      link() + `&do=getshort&ra=round&ft=areclist&elmt=roundStatus&id=Active`,
      {
        method: "post",
        headers: headers, timeout: 30000
        //body: JSON.stringify(data),
        //responseType: "blob",
      }
    ) // FETCH BLOB FROM IT
      .then((response) => response.json())
      .then((response) => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
      //  .console.log("Round list received=");
      //  .console.log(response);
        //rounds = response;
        this.setState({ rounds: response });
        //return response;
        this.getReport("");
      })
      .catch((err) => {
        console.log(err);
        if (err.code === 'ECONNABORTED') {
          alert("Server connection timed out");
      } else {
       // alert("Error occured");
      }
        this.setState({
          loading: false,
        });
      });

    //return rounds;
  };
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getRounds();
  };
  handleChangeSelect = (e) => {
    this.setState({
      loading: true,
    });
    let filters = this.state.filters;
    filters.bank = e.target.value;
    this.setState({
      reportType: e.target.value,
      filters: filters,
    });

  //  .console.log(
      // "Target= " + e.target.name + ", value= " + e.target.value
      //+
      //", content= " +
      //e.target.value
    // );

    this.getReport(e.target.value);
    //console.log(this.state.payment);
  };
  handleRoundSelect = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let filters = this.state.filters;
    //let fitlers = this.state.payment;
    filters.roundid = e.target.value;
    // payment.userID = e.target.value;
    this.setState({
      filters: filters,
    });

  //  .console.log(this.state.filters);

    this.getReport(this.state.reportType);
    //console.log(this.state.payment);
  };

  download = () => {
    var token = localStorage.getItem("token");

    let reptypedefault = this.state.reportType;

    if (reptypedefault === "") {
      return;
    }

    var postData = {
      datefrom: this.state.filters.datefrom,
      dateTo: this.state.filters.dateTo,
      userid: this.state.filters.userid,
      memberid: this.state.filters.memberid,
      roundid: this.state.filters.roundid,
      roundname: this.state.filters.roundname,
      name: this.state.filters.name,
      bank: this.state.filters.bank,
      termstartdate: this.state.filters.termstartdate,
      termduedate: this.state.filters.termduedate,
      status: this.state.filters.status,
    };
    var headers = {
      "Content-Type":
        "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: `${token}`,
    };

    downloadFile(
      "POST",
      link() + `&do=get&ra=report&rec=finstat` + `&ufile=getfile`,

      postData,
      headers, "FinancialStatements.xlsx"
    );
  };

  render() {
    const { reportResults, rounds, loading } = this.state;
    var length = Object.keys(reportResults).length;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Reports</h6>
            <div className="report">
              <Link to="/Main/IncomeReport" className="contrilink">
                Income
              </Link>
              <Link to="/Main/ExpenseReport" className="contrilink">
                Expense
              </Link>
              <Link to="/Main/Cashbook" className="contrilink">
                Cash Book
              </Link>
              <Link to="/Main/financialstatements" className="contrilink">
                Financial Statements
              </Link>
              {/* <Link to="/Main/otherreports" className="contrilink">
                Other Reports
              </Link> */}
            </div>

            <h6 className="activeuser mt-3 d-flex">
              <div className="col conteinpu">
                Financial Statements:
                <span className="unity"> {reportResults.roundName}</span>
              </div>
              {length > 0 ? (
                <div className="print" onClick={() => this.download()}>
                  <i class="fas fa-download"></i>
                </div>
              ) : (
                ""
              )}
            </h6>
            <div className="membaddy">
              <p className="parainputsapphf ">
                <div className=" conteinpu ">
                  Select Round:
                  <select
                    id="reporttype"
                    type="text"
                    name="reporttype"
                    onChange={(e) => this.handleRoundSelect(e)}
                    selected={this.state.filters.roundid}
                    className="logininputsselectyu"
                    placeholder="Select Bank type"
                  >
                    <option value="">Select Round</option>{" "}
                    {rounds &&
                      rounds.map((round) => {
                        return (
                          <option value={round.roundID}>
                            {round.roundName}
                          </option>
                        );
                      })}
                    {/* <option value="incomecontr">Round 9</option> */}
                  </select>
                </div>
              </p>
              {/* <p className="parainputsapphf ">
                <div className=" conteinpu ">
                  Select Account
                  <select
                    id="reporttype"
                    type="text"
                    name="reporttype"
                    // onChange={(e) => this.handleChangeSelect(e)}
                    // selected={this.state.reportType}

                    placeholder="Select Bank type"
                    className="logininputsselectyu"
                  >
                    <option value="incomecontr">
                      Income from Contributions
                    </option>
                    <option value="incomecontrpnlt">
                      Income from Penalties on Contributions
                    </option>
                    <option value="incomepnltothers">
                      Income from Penalties on Absence & Latency
                    </option>
                  </select>
                </div>
              </p> */}
              {/* <p className="parainputsapphf ">
                <div className=" conteinpu ">
                  <input
                    type="text"
                    id="search"
                    className="loginsearchfayui"
                    placeholder=" Search Users"
                  />
                </div>
              </p> */}
            </div>
            <div className="cardtable ">
              <div className="containeruserscroll">
                <div className="fistta">
                  <h4 className="titlesta">
                    STATEMENT OF FINANCIAL POSITION END OF ROUND '
                    {reportResults.roundName}'
                  </h4>
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <div className="statone">
                        <div className="totaldetails">
                          <div className="col conteinpu unity">
                            1. Income Statement
                          </div>
                        </div>
                        <br />

                        <table className="table ">
                          <tbody>
                            <tr className="tablebor">
                              <td className="userinfostat ">
                                Income from Loans
                              </td>
                              <td className="userinfostat ">
                                {numb2(reportResults.incomeFromLoans)}
                              </td>
                            </tr>
                            <tr>
                              <td className="userinfostat ">
                                Income from Contributions
                              </td>
                              <td className="userinfostat ">
                                {numb2(reportResults.incomeFromContributions)}
                              </td>
                            </tr>

                            <tr>
                              <td className="userinfostat ">Other Income</td>
                              <td className="userinfostat ">
                                {numb2(reportResults.incomeFromOthers)}
                              </td>
                            </tr>
                            <br />
                            <tr>
                              <td className="userinfostatde ">TOTAL INCOME</td>
                              <td className="userinfostat ">
                                {numb2(reportResults.incomeTotal)}
                              </td>
                            </tr>

                            <br />
                            <tr>
                              <td className="userinfostat ">
                                Administrative Expenses
                              </td>
                              {reportResults.administrativeExpenses ? (
                                <td className="userinfostat ">
                                  {numb2(reportResults.administrativeExpenses)}
                                </td>
                              ) : (
                                <td className="userinfostat ">-</td>
                              )}
                            </tr>
                            {/* <tr>
                        <td className="userinfostat ">
                          Administrative Expenses
                        </td>
                        {reportResults.administrativeExpenses ? (
                          <td className="userinfostat ">
                            {reportResults.administrativeExpenses}
                          </td>
                        ) : (
                          <td className="userinfostat ">-</td>
                        )}
                      </tr> */}
                            <tr>
                              <td className="userinfostat ">
                                Finance Expenses
                              </td>
                              {reportResults.financeExpenses ? (
                                <td className="userinfostat ">
                                  {numb2(reportResults.financeExpenses)}
                                </td>
                              ) : (
                                <td className="userinfostat ">-</td>
                              )}
                            </tr>
                            <tr>
                              <td className="userinfostat ">
                                Director Insentive
                              </td>
                              {reportResults.directorIncentive ? (
                                <td className="userinfostat ">
                                  {numb2(reportResults.directorIncentive)}
                                </td>
                              ) : (
                                <td className="userinfostat ">-</td>
                              )}
                            </tr>
                            <br />
                            <tr className="totaprofit">
                              <td className=" ">PROFIT</td>
                              {reportResults.profit ? (
                                <td className=" ">
                                  {numb2(reportResults.profit)}
                                </td>
                              ) : (
                                <td className="userinfostat ">-</td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div className="statone">
                        <div className="totaldetails">
                          <div className="col conteinpu unity">
                            2. Statement of Change in Equity
                          </div>
                        </div>
                        <br />
                        <table className="table ">
                          <tbody>
                            <tr>
                              <td className="userinfostat ">Share Capital</td>
                              {reportResults.totalShareValue ? (
                                <td className="userinfostat ">
                                  {numb2(reportResults.totalShareValue)}
                                </td>
                              ) : (
                                <td className="userinfostat ">-</td>
                              )}
                            </tr>
                            <tr>
                              <td className="userinfostat ">Monthly Reserve</td>
                              {reportResults.topaySavings ? (
                                <td className="userinfostat ">
                                  {numb2(reportResults.topaySavings)}
                                </td>
                              ) : (
                                <td className="userinfostat ">-</td>
                              )}
                            </tr>
                            <tr>
                              <td className="userinfostat ">
                                Profit at End of the Year
                              </td>
                              {reportResults.profit ? (
                                <td className="userinfostat ">
                                  {numb2(reportResults.profit)}
                                </td>
                              ) : (
                                <td className="userinfostat ">-</td>
                              )}
                            </tr>
                            <br />
                            <tr className="totaprofit">
                              <td className=" ">TOTAL</td>
                              {reportResults.nextTotalValueOfShares ? (
                                <td className=" ">
                                  {numb2(reportResults.nextTotalValueOfShares)}
                                </td>
                              ) : (
                                <td className="userinfostat ">-</td>
                              )}
                            </tr>
                            <br />
                            <tr>
                              <td className="userinfostatdy ">
                                Next Share value
                              </td>
                              {reportResults.nextShareValue ? (
                                <td className="userinfostatdy ">
                                  {numb2(reportResults.nextShareValue)}
                                </td>
                              ) : (
                                <td className="userinfostatdy ">-</td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div className="statone">
                        <div className="totaldetails">
                          <div className="col conteinpu unity">
                            3. Statement of Financial Position
                          </div>
                        </div>
                      </div>
                      <div className="unityli">ASSET</div>
                      <div className=" conteinputi">Fixed Asset</div>
                      <table className="table table-borderless align-items-center">
                        <tbody>
                          <tr>
                            <td className="userinfostat ">Investment</td>
                            {reportResults.investment ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.investment)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <tr>
                            <td className="userinfostat ">
                              Other Asset (Capital)
                            </td>
                            {reportResults.totalFixedAsset ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.assets)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>

                          <tr className="userinfostatde">
                            <td className="userinfostatde ">
                              TOTAL FIXED ASSETS
                            </td>
                            {reportResults.totalFixedAsset ? (
                              <td className="userinfostatde ">
                                {numb2(reportResults.totalFixedAsset)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                        </tbody>
                      </table>

                      <div className="conteinputi">Current Asset</div>
                      <table className="table table-borderless align-items-center">
                        <tbody>
                          <tr>
                            <td className="userinfostat ">Cash balance</td>
                            {reportResults.reserves ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.reserves)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <tr>
                            <td className="userinfostat ">Receivable</td>
                            {reportResults.receivable ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.receivable)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <tr>
                            <td className="userinfostat ">
                              {" "}
                              Others (Unspecified)
                            </td>
                            {reportResults.topayUnindentifiedIncome ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.topayUnindentifiedIncome)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>

                          <tr className="">
                            <td className="userinfostatde ">
                              TOTAL CURRENT ASSETS
                            </td>
                            {reportResults.totalCurrentAsset ? (
                              <td className="userinfostatde ">
                                {numb2(reportResults.totalCurrentAsset)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <br />
                          <tr className="totaprofit">
                            <td className="totuil ">TOTAL ASSETS</td>
                            {reportResults.totalAsset ? (
                              <td className=" ">
                                {numb2(reportResults.totalAsset)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                        </tbody>
                      </table>

                      <br />
                      <div className="unityli">EQUITY & LIABILITY</div>

                      <div className="conteinputi">Equity</div>
                      <table className="table table-borderless align-items-center">
                        <tbody>
                          <tr>
                            <td className="userinfostat ">Share Capital</td>
                            {reportResults.totalShareValue ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.totalShareValue)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <tr>
                            <td className="userinfostat ">Monthly Reserves</td>
                            {reportResults.topaySavings ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.topaySavings)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <tr>
                            <td className="userinfostat ">Other Reserves</td>
                            {reportResults.existingCash ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.existingCash)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <tr>
                            <td className="userinfostat "> Profit of Year</td>
                            {reportResults.profitOfYear ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.profitOfYear)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>

                          <tr className="">
                            <td className="userinfostatde ">TOTAL EQUITY</td>
                            {reportResults.totalEquity ? (
                              <td className="userinfostatde ">
                                {numb2(reportResults.totalEquity)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                        </tbody>
                      </table>

                      <div className="conteinputi">Liability</div>
                      <table className="table table-borderless align-items-center">
                        <tbody>
                          <tr>
                            <td className="userinfostat ">Payable</td>
                            {reportResults.payable ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.payable)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <tr>
                            <td className="userinfostat ">
                              Director's Incentive
                            </td>
                            {reportResults.directorIncentive ? (
                              <td className="userinfostat ">
                                {numb2(reportResults.directorIncentive)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>

                          <tr>
                            <td className="userinfostatde ">
                              TOTAL LIABILITIES
                            </td>
                            {reportResults.totalEquityAndLiability ? (
                              <td className="userinfostatde ">
                                {numb2(reportResults.totalEquityAndLiability)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <br />
                          <tr className="totaprofit">
                            <td className=" totuil">
                              {" "}
                              TOTAL EQUITY AND LIABILITY
                            </td>
                            {reportResults.totalLiability ? (
                              <td className=" ">
                                {numb2(reportResults.totalLiability)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                          <br />
                          <tr className="totaprofit ">
                            <td className=" totuil"> TOTAL EQ & LB - ASSET</td>
                            {reportResults.difference ? (
                              <td className=" ">
                                {numb2(reportResults.difference)}
                              </td>
                            ) : (
                              <td className="userinfostat ">-</td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Financialstatements;
