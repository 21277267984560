import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../../Auth";
import Navbar from "../../Navbar";
import SignOut from "../../SignOut";
import { link, gotopage, goto, numb2 } from "../../link.js";
import axios from "axios";
import Footer from "../../footer";
import Loading from "../../loading";
export class Commissions extends Component {
  constructor() {
    super();
    this.state = {
      cont: [],
      loading: false,
      searchItem: "",
      roundid: "",
      action: "",
      actionid: "",
      actionon: "",
      actionamount: 0,
    };
  }
  // handleCreateRound = () => {
  //   window.location.href = window.location + "Main/CreateRound";
  // };
  // handleEditRond = () => {
  //   window.location.href = window.location + "Main/EditRound";
  // };
  // handleMigrateRond = () => {
  //   window.location.href = window.location + "Main/MigrateRound";
  // };
  handleContribution = () => {
    goto("Main/Contribution/" + this.state.roundid);
  };
  handleDistribution = () => {
    goto("Main/Distribution/" + this.state.roundid);
  };
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getContribution();
  };
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  getContribution() {
    var token = localStorage.getItem("token");
    let path = window.location.hash;
    let roundId = path.split("/")[3] || "";

    this.setState({ roundid: roundId });
    var config = {
      method: "post",
      url:
        link() +
        `&do=getcontributions&ra=round&ft=rec&elmt=roundID&id=` +
        roundId,
      headers: {
        Authorization: `${token}`,
      },
      timeout: 30000
    };

    axios(config)
      .then((response) => {
        const sums = [];
        let ytdtopay = 0;
        let ytdpaid = 0;
        let totalshares = 0;
        let valueshares = 0;
        let totalPenalities = 0;
        response.data.attachement.forEach((item, indx) => {
          item.attachment.Commission &&
            item.attachment.Commission.attachment.forEach((itm, index) => {
              if (sums[index]) {
                sums.splice(index, 1, {
                  topay:
                    Number.parseFloat(sums[index].topay) +
                    Number.parseFloat(itm.topay),

                  balance:
                    Number.parseFloat(sums[index].balance) +
                    Number.parseFloat(itm.balance),

                  paid:
                    Number.parseFloat(sums[index].paid) +
                    Number.parseFloat(itm.paid),
                });
              } else {
                sums.push({
                  paid: Number.parseFloat(itm.paid),
                  topay: Number.parseFloat(itm.topay),
                  balance: Number.parseFloat(itm.balance),
                });
              }
            });
        });
        //console.log(sums);
        this.setState({
          cont: response.data,
          sums,
          roundid: response.data.roundID,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'ECONNABORTED') {
          alert("Server connection timed out");
      } else {
       // alert("Error occured. commission");
      }
        this.setState({
          loading: false,
        });
      });
  }

  handleChange = (e) => {
    //let item = this.state.e.target.name;
    // users[e.target.name] = e.target.value;
    this.setState({ actionamount: e.target.value });
  };

  takeAction(e, action, amount) {
    e.preventDefault();

    if (action === "convert")
      gotopage(
        "convert",
        action +
          this.state.action +
          "/" +
          this.state.actionon +
          "/" +
          this.state.actionid +
          "/" +
          this.state.actionamount
      );

    if (action === "giveback") {
      var token = localStorage.getItem("token");
      var config = {
        method: "post",
        url:
          link() +
          `&do=${action}&ra=spending&elmt=${this.state.actionon}&amount=${this.state.actionamount}&id=` +
          this.state.actionid,
        headers: {
          Authorization: `${token}`,
        }, timeout : 30000
      };

      axios(config)
        .then((response) => {
        //  console.log("Response for giveback=");
        //  console.log(response.data);
          this.closeModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  openModal = (id, actiononitem, amount) => {
  //  console.log("Modal Opened with id " + id);
    this.setState({
      openModal: true,
      actionid: id,
      action: "",
      actionon: actiononitem,
      actionamount: amount,
    });
  };
  closeModal = () => {
    this.setState({
      openModal: false,
      actionid: "",
      action: "",
      actionon: "",
      actionamount: 0,
    });
  };

  render() {
    const { cont, sums, roundid, loading } = this.state;
  //  console.log(cont);
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Contributions</h6>
            <div className="searchadd">
              <button className="btndistr" onClick={this.handleContribution}>
                Contribution
              </button>
              <button
                className="btndistr"
                onClick={this.handleDistribution}
                disabled={loading}
              >
                Round Payment
              </button>
              {/* <input
                type="text"
                id="search"
                className="loginsearchfa"
                placeholder=" Search a Round here…
                "
              /> */}
            </div>
            <div className="activeuser">
              <div class="row ">
                <div class=" col-lg-3">
                  <div className="col conten">
                    Round Name:
                    
                    <span className="unity">{cont.name}</span>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div className="col conten">
                    Start Date:
                    <span className="unity">
                      {cont.startDate
                        ? cont.startDate.toString().substring(0, 10)
                        : ""}
                    </span>
                  </div>
                </div>
                <div class="col col-lg-3">
                  <div className="col conten">
                    End Date:
                    <span className="unity">
                      {" "}
                      {cont.endDate
                        ? cont.endDate.toString().substring(0, 10)
                        : ""}
                    </span>
                  </div>
                </div>
                <div class="col col-lg-3">
                  <div className="col conten">
                    Current Month:
                    <span className="unity"> </span>
                  </div>
                </div>
                <div class="col col-lg-3.5">
                  <div className="col conten">
                    Number of Members:
                    <span className="unity">
                      {cont.attachement && cont.attachement.length}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailedcon ">
              <Link to={"/Main/Contribution/" + roundid} className="contrilink">
                Contribution
              </Link>
              <Link to={"/Main/Capital/" + roundid} className="contrilink">
                Capital
              </Link>
              <Link to={"/Main/Penalties/" + roundid} className="contrilink">
                Penalties
              </Link>
              <Link to={"/Main/Commissions/" + roundid} className="contrilink">
                Commissions
              </Link>
            </div>
            <div className="cardtable ">
              <div className="containeruserscroll">
                <div className="membadd">
                  <p className=" contentmemb "> Commission</p>
                  <input
                    type="text"
                    id="search"
                    className="loginsearchfaro mb-3"
                    placeholder=" Search a name..."
                    onChange={(e) => this.handleUserChange(e)}
                  />
                  {/* <input
                  type="text"
                  id="search"
                  className="loginsearchfaro"
                  placeholder=" Search a name...
                "
                /> */}
                </div>
                <div className="cardtablecapital">
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <table className="table table-hover  table-responsive   ">
                        <thead>
                          <tr>
                            <th className="userprony "></th>
                            <th className="userprony ">Commission to pay</th>
                            <th className="userprony ">Paid Commission</th>
                            <th className="userprony ">Balance</th>
                            <th className="userprony ">Pay Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="userpronou "></td>
                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userpronou ">
                                    {numb2(totalpay.topay)}
                                  </td>
                                );
                              })}
                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userpronou ">
                                    {numb2(totalpay.paid)}
                                  </td>
                                );
                              })}
                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userpronou ">
                                    {numb2(totalpay.balance)}
                                  </td>
                                );
                              })}
                            <td className="userpronou "></td>
                          </tr>
                          {cont.attachement &&
                            cont.attachement.map((item) => {
                              return (
                                item.attachment.Commission &&
                                item.attachment.Commission.attachment
                                  .filter((grd) =>
                                    grd.name
                                      ?.toLowerCase()
                                      .includes(this.state.searchItem)
                                  )
                                  .map((com) => {
                                    var vbalance = Number.parseFloat(
                                      com.balance
                                    );
                                    return (
                                      <tr>
                                        <td className="userpron ">
                                          {" "}
                                          {com.name}
                                        </td>
                                        <td className="userpronouwe ">
                                          {numb2(com.topay)}
                                        </td>
                                        <td className="userpronouwe ">
                                          {numb2(com.paid)}
                                        </td>
                                        {vbalance > 0 ? (
                                          <td
                                            className="userpronouwe redColor cursorPoint"
                                            onClick={() =>
                                              this.openModal(
                                                com.itemid,
                                                "contribution",
                                                vbalance
                                              )
                                            }
                                          >
                                            {com.balance}
                                          </td>
                                        ) : (
                                          <td className="userpronouwe ">
                                            {numb2(com.balance)}
                                          </td>
                                        )}
                                        <td className="userpronouwe ">
                                          {com.paidDate}
                                        </td>
                                      </tr>
                                    );
                                  })
                              );
                            })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
            <br />

            {/* <>-----------------------------------------------------------------</> */}

            <Modal show={this.state.openModal} className="modalAll">
              <Modal.Body className="Madl">
                <div className="detmod">
                  <div>
                    <p className="closeModale" onClick={this.closeModal}>
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                  <div className=" conteinputitle ">
                    What action do you want to take?
                  </div>
                  <div className="d-flex">
                    <div className="conteinpu ml-3">
                      Select the action you would like to take
                      {/* <p className="unity"> Give Back</p>
                      <p className="unity">Convert</p> */}
                      <div class="form-group">
                        <label className="lebelenter">Amount (Rwf):</label>

                        <input
                          type="text"
                          id="actionamount"
                          name="actionamount"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.actionamount}
                          className="logininputsele"
                          placeholder="Enter amount"
                        />
                      </div>
                      <button
                        className="viewdetcli ml-3"
                        method="post"
                        onClick={(e) => this.takeAction(e, "giveback")}
                      >
                        Give Back
                      </button>
                      <button
                        className="viewdetcli ml-3"
                        method="post"
                        onClick={(e) => this.takeAction(e, "convert")}
                      >
                        Convert
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Commissions;
