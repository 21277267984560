import React, { Component } from "react";
import Navbar from "../Navbar";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../SignOut";
import { link, goto, numb2 } from "../link.js";
import axios from "axios";
import Footer from "../footer";
import Loading from "../loading";
export class LoanDetails extends Component {
  constructor() {
    super();
    this.state = {
      LoanDetails: {},
      loading: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getLoanDetatils();
  };
  getLoanDetatils = () => {
    let path = window.location.hash;
  //  .console.log("path= " + path);
    let userId = path.split("/")[3];
  //  .console.log("id= " + userId);
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: link() + `&do=getfull&ra=loan&ft=areclist&elmt=loanID&id=${userId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },timeout: 5000
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);

        if (
          Array.isArray(response.data) &&
          Object.keys(response.data[0]).length > 0
        ) {
          this.setState({
            LoanDetails: response.data[0],
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleLoan = () => {
    //window.location.href = window.location + "Main/Allloans";
    goto("Main/Allloans");
  };
  handlepayment = () => {
    //window.location.href = window.location + "Main/RecordPayment";
    goto("Main/RecordPayment");
  };
  render() {
    const { LoanDetails, loading } = this.state;
    var cpandint =
      Number.parseFloat(LoanDetails.capitalpaid) +
      Number.parseFloat(LoanDetails.interestpaid);
  //  .console.log(LoanDetails);
    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">Loan</h6>
        </div>

        <div className="searchexp">
          <button
            className="btndistr"
            onClick={this.handleLoan}
            disabled={loading}
          >
            Loans
          </button>
          {LoanDetails.editable === "yes" ? (
            <button
              className="btndistr"
              onClick={() => goto("Main/LoanEdit/" + LoanDetails.loanid)}
              disabled={loading}
            >
              Edit
            </button>
          ) : (
            ""
          )}

          <button
            className="btndistr"
            onClick={this.handlepayment}
            disabled={loading}
          >
            Add payment
          </button>
        </div>

        <h6 className="activeuser"> Loan Details</h6>
        <div className=" cardtable">
          <form className="formcreate " method="post">
            <div className="rowuno ">
              <p className="parainputs">
                <div className="col conteinpu">
                  Beneficiary:
                  <span className="unity"> {LoanDetails.names}</span>
                </div>
              </p>
              <p className="parainputs ">
                {" "}
                <div className="col conteinpu ">
                  Requestor:
                  <span className="unity"> {LoanDetails.requestor}</span>
                </div>
              </p>
              <p className="parainputs ">
                {" "}
                <div className="col conteinpu">
                  Start Date:
                  <span className="unity">
                    {" "}
                    {LoanDetails.effectivedate
                      ? LoanDetails.effectivedate.substring(0, 10)
                      : ""}
                  </span>
                </div>
              </p>
            </div>
            <div className="rowuno ">
              <p className="parainputs">
                <div className="col conteinpu">
                  Share Value:
                  <span className="unity">
                    {LoanDetails.currency} {numb2(LoanDetails.shareValue)}
                  </span>
                </div>
              </p>
              <p className="parainputs ">
                <div className="col conteinpu">
                  Terms:
                  <span className="unity">{LoanDetails.terms}</span>
                </div>
              </p>
              <p className="parainputs ">
                <div className="col conteinpu">
                  Application fee rate:
                  <span className="unity">
                    {numb2(LoanDetails.applicationfeerate)}%
                  </span>
                </div>
              </p>
            </div>

            <div className="rowuno ">
              <p className="parainputs">
                <div className="col conteinpu">
                  Capital Amount:
                  <span className="unity">
                    {" "}
                    {LoanDetails.currency} {numb2(LoanDetails.capitaltorigin)}
                  </span>
                </div>
              </p>

              <p className="parainputs ">
                {" "}
                <div className="col conteinpu">
                  Request Date:
                  <span className="unity">
                    {" "}
                    {LoanDetails.requestdate
                      ? LoanDetails.requestdate.toString().substring(0, 10)
                      : ""}
                  </span>
                </div>
              </p>
              <p className="parainputs">
                <div className="col conteinpu">
                  Paid Date :
                  <span className="unity">
                    {" "}
                    {LoanDetails.loanpaiddate
                      ? LoanDetails.loanpaiddate.toString().substring(0, 10)
                      : ""}
                  </span>
                </div>
              </p>
            </div>

            <div className="rowuno ">
              <p className="parainputs">
                <div className="col conteinpu">
                  Interest Rate (%):
                  <span className="unity">
                    {" "}
                    {LoanDetails && LoanDetails.rate}
                  </span>
                </div>
              </p>

              <p className="parainputs ">
                {" "}
                <div className="col conteinpu">
                  Approval Date:
                  <span className="unity">
                    {" "}
                    {LoanDetails.approvaldate
                      ? LoanDetails.approvaldate.toString().substring(0, 10)
                      : ""}
                  </span>
                </div>
              </p>
              <p className="parainputs">
                <div className="col conteinpu">
                  Loan ID :<span className="unity"> {LoanDetails.loanid}</span>
                </div>
              </p>

              {LoanDetails.approvalstatus === "Revised" ? (
                <p className="parainputs">
                  <div className="col conteinpu">
                    Status:
                    <span className="unity redColor"> Under Revision</span>
                  </div>
                </p>
              ) : (
                ""
              )}
            </div>

            <br />
          </form>
          {loading ? (
            <Loading />
          ) : (
            <>
              {LoanDetails.loanstatus != "Pending" ? (
                <table className="table table-hover  table-responsive  tablealot">
                  <thead>
                    <th className="userprony ">Due Date</th>
                    <th className="userprony ">Capital Request</th>
                    <th className="userprony ">Interest</th>
                    <th className="userprony ">Penalities Amount</th>
                    <th className="userprony ">Others</th>
                    <th className="userprony ">Total Due</th>
                    <th className="userprony ">Paid Date</th>
                    <th className="userprony "> Paid Capital</th>
                    <th className="userprony ">Paid Interest</th>
                    <th className="userprony ">Paid Cap+Int</th>
                    <th className="userprony ">Late Days</th>
                    <th className="userprony ">Paid Penalities</th>
                    <th className="userprony ">Total Paid Amount</th>
                    <th className="userprony ">Balance</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="userpronou "></td>
                      <td className="userpronou ">
                        {numb2(LoanDetails.capitaltopay)}
                      </td>
                      <td className="userpronou ">
                        {numb2(LoanDetails.interesttopay)}
                      </td>
                      <td className="userpronou ">
                        {numb2(LoanDetails.penaltytopay)}
                      </td>
                      <td className="userpronou ">
                        {numb2(LoanDetails.othertopay)}
                      </td>
                      <td className="userpronou ">
                        {numb2(LoanDetails.topay)}
                      </td>
                      <td className="userpronou ">{LoanDetails.paidate}</td>
                      <td className="userpronou ">
                        {" "}
                        {numb2(LoanDetails.capitalpaid)}
                      </td>
                      <td className="userpronou ">
                        {numb2(LoanDetails.interestpaid)}
                      </td>
                      <td className="userpronou ">
                        {numb2(
                          Number.parseFloat(LoanDetails.capitalpaid) +
                            Number.parseFloat(LoanDetails.interestpaid)
                        )}
                      </td>
                      <td className="userpronou ">
                        {numb2(LoanDetails.penaltydays)}
                      </td>
                      <td className="userpronou ">
                        {numb2(LoanDetails.penaltypaid)}
                      </td>
                      <td className="userpronou ">
                        {" "}
                        {numb2(LoanDetails.paid)}
                      </td>
                      <td className="userpronou ">
                        {" "}
                        {numb2(LoanDetails.balance)}
                      </td>
                    </tr>
                    {LoanDetails.attachment &&
                      LoanDetails.attachment.sort().map((item) => {
                        if (item.category.toLowerCase() === "installment") {
                          return (
                            <tr>
                              <td className="userpron ">
                                {item.type.toLowerCase() === "installment"
                                  ? item.duedate
                                    ? item.duedate.toString().substring(0, 10)
                                    : ""
                                  : "Interest"}
                              </td>
                              <td className="userpron ">
                                {" "}
                                {numb2(item.capitaltopay)}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.interesttopay)}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.penaltytopay)}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.othertopay)}
                              </td>
                              <td className="userinfo ">{numb2(item.topay)}</td>
                              <td className="userinfo ">
                                {" "}
                                {item.paiddate
                                  ? item.paiddate.toString().substring(0, 10)
                                  : ""}
                              </td>

                              <td className="userinfo ">
                                {numb2(item.capitalpaid)}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.interestpaid)}
                              </td>
                              <td className="userinfo ">
                                {numb2(
                                  Number.parseFloat(item.capitalpaid) +
                                    Number.parseFloat(item.interestpaid)
                                )}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.latedays)}
                                {/* {item.penaltypaiddate
                                  ? item.penaltypaiddate
                                      .toString()
                                      .substring(0, 10)
                                  : ""}{" "} */}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.penaltypaid)}
                              </td>
                              <td className="userinfo ">{numb2(item.paid)}</td>
                              <td className="userinfo ">
                                {numb2(item.balance)}
                              </td>
                            </tr>
                          );
                        }
                      })}

                    {LoanDetails.attachment &&
                      LoanDetails.attachment.sort().map((item) => {
                      //  .console.log(item);
                        if (item.category.toLowerCase() !== "installment") {
                          return (
                            <tr>
                              <td className="userpron " colspan="4">
                                {item.description}
                              </td>

                              <td className="userinfo ">
                                {numb2(item.othertopay)}
                              </td>
                              <td className="userinfo ">{numb2(item.topay)}</td>
                              <td className="userinfo ">
                                {" "}
                                {item.paiddate
                                  ? item.paiddate.toString().substring(0, 10)
                                  : ""}
                              </td>

                              <td className="userinfo ">
                                {numb2(item.capitalpaid)}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.interestpaid)}
                              </td>
                              <td className="userinfo ">
                                {numb2(
                                  parseFloat(item.capitalpaid) +
                                    parseFloat(item.interestpaid)
                                )}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.latedays)}
                              </td>
                              <td className="userinfo ">
                                {numb2(item.penaltypaid)}
                              </td>
                              <td className="userinfo ">{numb2(item.paid)}</td>
                              <td className="userinfo ">
                                {numb2(item.balance)}
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
              ) : (
                <div className="col contdens">
                  {" "}
                  The loan is not yet approved
                </div>
              )}
            </>
          )}
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default LoanDetails;
