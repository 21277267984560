import React, { useState, useEffect } from "react";
import Footer from "../footer";
import Navbar from "../Navbar";
import { Row, Col, Container, Modal } from "react-bootstrap";
import SignOut from "../SignOut";
import axios from "axios";
import Auth from "../../Auth";
import { link, numb2 } from "../link.js";
import { Link, withRouter } from "react-router-dom";
import Switch from "@mui/material/Switch";

const Profile = () => {
  const [isOn, setIsOn] = useState(false);
  const [opModal, setopModal] = useState(false);
  const [Email, setEmail] = useState(null);
  const [Emails, setEmails] = useState([]);
  const [Task, setTask] = useState([]);

  useEffect(
    () => {
      // Fetch the initial state from the API
      getEmail();
      getTask();
    },
    [],
    []
  );
  const getTask = () => {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "1502930012",
    };
    axios
      .post(
        link() + `&do=get&ra=task&name=&taskID`,
        {},
        {
          headers: headers, timeout: 30000
        }
      )
      .then((response) => {
        console.log(response.data);
        setTask(response.data);
      })
      .catch((error) => {
        console.error("Error fetching the feature status:", error);
        
      });
  };
  const getEmail = () => {
    var token = localStorage.getItem("token");
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    axios
      .post(
        link() + `&do=getemail&ra=task&=`,
        {},
        {
          headers: headers, timeout:30000
        }
      )
      .then((response) => {
        setEmails([response.data]);
      })
      .catch((error) => {
        console.error("Error fetching the feature status:", error);
        // this.setState({
        //   loading: false,
        // });
      });
  };
  const handleSwitchChange = (selected) => {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "1502930012",
    };
    var status = selected.emailStatus === "Inactive" ? "Active" : "Inactive";

    var activate = {
      username: selected.email,
      emailStatus: status,
      title: selected.title,
    };

    axios
      .post(link() + `&do=updateemail&ra=task&name=monthlyreport`, activate, {
        headers: headers,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating the feature status:", error);
      });

    // Send the activation/deactivation request to the API
  };
  const handleSwitchChangeTask = (selc) => {

    var token = localStorage.getItem("token");
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    var status = selc.taskStatus === "Inactive" ? "Active" : "Inactive";
    var activate = {
      period: selc.period,
      name: selc.name,
      periodName: selc.periodName,
      message: selc.message,
      taskID: selc.taskID,
      taskStatus: status,
      nextRu: selc.nextRu,
    };

    axios
      .post(
        link() + `&do=status&ra=task&name=${selc.name}&taskStatus=${status}`,
        activate,
        {
          headers: headers,
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating the feature status:", error);
      });
  };
  const openModal = () => {
    setopModal(true);
  };
  const closeModal = () => {
    setopModal(false);
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const takeAction = () => {
    console.log("enter api");
  };
  console.log(Task);
  return (
    <div>
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <br />
        <h6 className="activejobs">System Settings</h6>
        <div className="membadd ">
          <Link to="/Main/Members" className="contrilink">
            Users
          </Link>
          <Link to="/Main/Assets" className="contrilink">
            Assets
          </Link>
          <Link to="/Main/Usergroups" className="contrilink">
            User Groups
          </Link>
          <Link to="/Main/Adminsettings" className="contrilink">
            Round Settings
          </Link>
          <Link to="/Main/Profile" className="contrilink">
            Admin Settings
          </Link>
        </div>

        <div className=" cardtable mt-3">
          <div className="containeruserscroll">
            <div className="membadd d-flex">
              <p className=" contentmemb">Task Settings</p>
            </div>

            <table className="table table-hover  table-responsive  tablealot ">
              <thead>
                <tr>
                  <th className="userpronyblue ">message</th>
                  <th className="userpronyblue">Enable</th>
                </tr>
              </thead>
              <tbody>
                {Task &&
                  Task.map((item, index) => {
                    console.log(item.message + "/" + item.taskStatus);
                    return (
                      <tr key={item.taskID}>
                        <td className="userinfowe ">{item.message} </td>
                        <td className="userinfowe  ">
                          <td className="userinfowe  ">
                            <Switch
                              checked={
                                item.taskStatus === "Active" ? true : false
                              }
                              onChange={() => handleSwitchChangeTask(item)}
                              color="primary"
                              name="featureSwitch"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </td>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <div className=" cardtable">
          <div className="containeruserscroll">
            <div className="membadd d-flex">
              <p className="contentmemb">Email Settings</p>
            </div>

            <table className="table table-hover  table-responsive  tablealot ">
              <thead>
                <tr>
                  <th className="userpronyblue ">Email</th>
                  <th className="userpronyblue ">Enable</th>
                </tr>
              </thead>
              <tbody>
                {Emails &&
                  Emails.map((item) => {
                    return (
                      <tr key={item.emailID}>
                        {/* <td className="userinfowe ">{item.title} </td> */}
                        <td className="userinfowe ">{item.email} </td>

                        <td className="userinfowe  ">
                          <Switch
                            checked={
                              item.emailStatus === "Active" ? true : false
                            }
                            onChange={() => handleSwitchChange(item)}
                            color="primary"
                            name="featureSwitch"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <Modal show={opModal} className="modalAll">
          <Modal.Body className="Madl">
            <div className="detmod">
              <div>
                <p className="closeModale" onClick={closeModal}>
                  <i className="fas fa-times"></i>
                </p>
              </div>

              <div className="d-flex">
                <div className="conteinpu ml-3">
                  <div class="form-group">
                    <label className="lebelenter">Add new email</label>

                    <input
                      type="text"
                      id="actionamount"
                      name="Email"
                      onChange={(e) => handleChange(e)}
                      value={Email}
                      className="logininputsele"
                      //placeholder="Enter date: YYYY-MM"
                    />
                  </div>
                  <button
                    className="viewdetcli ml-3"
                    method="post"
                    onClick={() => takeAction()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default Profile;
