import React, { Component } from "react";
import { Row, Col, Container, ResponsiveEmbed, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../../Auth";
import Navbar from "../../Navbar";
import SignOut from "../../SignOut";
import { link, gotopage, goto, numb2 } from "../../link.js";
import axios from "axios";
import Footer from "../../footer";
import Loading from "../../loading";
export class Contribution extends Component {
  constructor() {
    super();
    this.state = {
      cont: [],
      sums: [],
      ytdtopay: 0,
      ytdpaid: 0,
      totalshares: 0,
      valueshares: 0,
      membershipnumber: 0,
      openModal: false,
      roundid: "",
      action: "",
      actionid: "",
      actionon: "",
      actionamount: 0,
      searchItem: "",
      loading: false,
    };
  }
  // handleCreateRound = () => {
  //   window.location.href = window.location + "Main/CreateRound";
  // };
  // handleEditRond = () => {
  //   window.location.href = window.location + "Main/EditRound";
  // };
  // handleMigrateRond = () => {
  //   window.location.href = window.location + "Main/MigrateRound";
  // };
  handleContribution = () => {
    goto("Main/Contribution/" + this.state.roundid);
  };
  handleDistribution = () => {
    goto("Main/Distribution/" + this.state.roundid);
  };
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getContribution();
  };
  getContribution() {
    var token = localStorage.getItem("token");
    let path = window.location.hash;
    let roundId = path.split("/")[3] || "";

    this.setState({ roundid: roundId });
    var config = {
      method: "post",
      url:
        link() +
        `&do=getcontributions&ra=round&ft=rec&elmt=roundID&id=` +
        roundId,
      headers: {
        Authorization: `${token}`,
      },
      timeout: 20000
    };

    axios(config)
      .then((response) => {
       // console.log("Get contribution response =");
       // console.log(response.data);
        const sums = [];
        let ytdtopay = 0;
        let ytdpaid = 0;
        let totalshares = 0;
        let valueshares = 0;
        let membershipnumber = 0;
        response.data.attachement &&
          response.data.attachement.forEach((item, indx) => {
            item.attachment.Contribution && item.attachment.Contribution.attachment
              .sort(function sort(a, b) {
                var aa = a.month.split("-"),
                  bb = b.month.split("-");
                return aa[0] - bb[0] || aa[1] - bb[1];
              })
              .forEach((itm, index) => {
                if (sums[index]) {
                  sums.splice(index, 1, {
                    topay:
                      Number.parseFloat(sums[index].topay) +
                      Number.parseFloat(itm.capitaltopay),
                    paid:
                      Number.parseFloat(sums[index].paid) +
                      Number.parseFloat(itm.capitalpaid),
                  });
                } else {
                  sums.push({
                    topay: Number.parseFloat(itm.capitaltopay),
                    paid: Number.parseFloat(itm.capitalpaid),
                  });
                }
              });
            valueshares =
              Number.parseFloat(valueshares) +
              Number.parseFloat(item.sharesValue);
            totalshares =
              Number.parseFloat(totalshares) +
              Number.parseFloat(item.numberOfShares);
           
           
           if(item.attachment.Contribution) {   ytdtopay =
              ytdtopay +
              Number.parseFloat(item.attachment.Contribution.capitaltopay);
            ytdpaid =
              ytdpaid +
              Number.parseFloat(item.attachment.Contribution.capitalpaid); }

            membershipnumber =
              membershipnumber + Number.parseFloat(item.numberOfMemberships);
          });
        //console.log(sums);
        this.setState({
          cont: response.data,
          sums,
          ytdtopay,
          ytdpaid,
          totalshares,
          valueshares,
          membershipnumber,
          roundid: response.data.roundID,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'ECONNABORTED') {
        //  alert("Server connection timed out");
      } else {
       // alert("Error occured. contribution");
      }
        this.setState({
          loading: false,
        });
      });
  }
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };

  handleChange = (e) => {
    //let item = this.state.e.target.name;
    // users[e.target.name] = e.target.value;
    this.setState({ actionamount: e.target.value });
  };

  takeAction(e, action, amount) {
    e.preventDefault();

    if (action === "convert")
      gotopage(
        "convert",
        action +
          this.state.action +
          "/" +
          this.state.actionon +
          "/" +
          this.state.actionid +
          "/" +
          this.state.actionamount
      );

    if (action === "giveback") {
      var token = localStorage.getItem("token");
      var config = {
        method: "post",
        url:
          link() +
          `&do=${action}&ra=spending&elmt=${this.state.actionon}&amount=${this.state.actionamount}&id=` +
          this.state.actionid,
        headers: {
          Authorization: `${token}`,
        },
        timeout: 5000
      };

      axios(config)
        .then((response) => {
         // console.log("Response for giveback=");
         // console.log(response.data);
          this.closeModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  openModal = (id, actiononitem, amount) => {
   // console.log("Modal Opened");
    this.setState({
      openModal: true,
      actionid: id,
      action: "",
      actionon: actiononitem,
      actionamount: amount,
    });
  };
  closeModal = () => {
    this.setState({
      openModal: false,
      actionid: "",
      action: "",
      actionon: "",
      actionamount: 0,
    });
  };

  render() {
    const {
      cont,
      sums,
      ytdtopay,
      ytdpaid,
      totalshares,
      valueshares,
      membershipnumber,
      roundid,
      loading,
      actionamount,
    } = this.state;
   // console.log(ytdtopay);
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Contributions</h6>
            <div className="searchadd">
              <button className="btndistr" onClick={this.handleContribution}>
                Contribution
              </button>
              <button
                className="btndistr"
                onClick={this.handleDistribution}
                disabled={loading}
              >
                Round Payment
              </button>
              {/* <input
                type="text"
                id="search"
                className="loginsearchfa"
                placeholder=" Search a Round here…
                "
              /> */}
            </div>
            <div className="activeuser">
              <div class="row ">
                <div class=" col-lg-3">
                  <div className="col conten">
                    Round Name:
                    
                    <span className="unity">{cont.name}</span>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div className="col conten">
                    Start Date:
                    <span className="unity">
                      {cont.startDate
                        ? cont.startDate.toString().substring(0, 10)
                        : ""}
                    </span>
                  </div>
                </div>
                <div class="col col-lg-3">
                  <div className="col conten">
                    End Date:
                    <span className="unity">
                      {" "}
                      {cont.endDate
                        ? cont.endDate.toString().substring(0, 10)
                        : ""}
                    </span>
                  </div>
                </div>
                <div class="col col-lg-3">
                  <div className="col conten">
                    Current Month:
                    <span className="unity"> </span>
                  </div>
                </div>
                <div class="col col-lg-3.5">
                  <div className="col conten">
                    Number of Members:
                    <span className="unity">
                      {cont.attachement && cont.attachement.length}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailedcon ">
              <Link to={"/Main/Contribution/" + roundid} className="contrilink">
                Contribution
              </Link>
              <Link to={"/Main/Capital/" + roundid} className="contrilink">
                Capital
              </Link>
              <Link to={"/Main/Penalties/" + roundid} className="contrilink">
                Penalties
              </Link>
              <Link to={"/Main/Commissions/" + roundid} className="contrilink">
                Commissions
              </Link>
            </div>
            <div className=" cardtable">
              <div className="containeruserscroll">
                <div className="membadd d-flex">
                  <p className=" contentmemb">Members</p>
                  <input
                    type="text"
                    id="search"
                    className="loginsearchfaro"
                    placeholder=" Search a name..."
                    onChange={(e) => this.handleUserChange(e)}
                  />
                  {/* <p onClick={() => this.openModal("1")}>Test Moadal</p> */}
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <table className="table table-hover  table-responsive  tablealot">
                      <thead>
                        <tr>
                          <th className="userpronou ">Names</th>
                          <th className="userpronou ">Shares</th>
                          <th className="userpronou ">Value of Shares</th>
                          <th className="userpronou ">
                            Round Payment
                            <br />
                            Shares
                          </th>

                          <th className="userpronou "></th>
                          <th className="userpronou ">YTD</th>
                          {cont.attachement &&
                            cont.attachement[0] && cont.attachement[0].attachment.Contribution &&
                            cont.attachement[0].attachment.Contribution.attachment
                              .sort(function sort(a, b) {
                                let aa = a.month.split("-"),
                                  bb = b.month.split("-");
                                return aa[0] - bb[0] || aa[1] - bb[1];
                              })
                              .map((year) => {
                                return (
                                  <th className="userpronou">{year.month}</th>
                                );
                              })}
                          <th className="userpronou"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="userprony "></td>
                          <td className="userprony ">{totalshares}</td>
                          <td className="userprony ">{numb2(valueshares)}</td>
                          {/* <td className="userprony ">{totalpay.membershipnumber}</td> */}

                          <td className="userprony ">{membershipnumber}</td>

                          <td className="userprony ">To pay</td>
                          <td className="userprony ">{numb2(ytdtopay)}</td>
                          {sums &&
                            sums.map((totalpay) => {
                              return (
                                <td className="userprony ">
                                  {numb2(totalpay.topay)}
                                </td>
                              );
                            })}
                        </tr>
                        <tr>
                          <td className="userpronouwe "></td>
                          <td className="userpronouwe "></td>
                          <td className="userpronouwe "></td>
                          <td className="userpronouwe "></td>
                          <td className="userpronouwe ">Paid</td>
                          <td className="userpronouwe ">{numb2(ytdpaid)}</td>
                          {sums &&
                            sums.map((totalpay) => {
                              return (
                                <td className="userpronouwe ">
                                  {numb2(totalpay.paid)}
                                </td>
                              );
                            })}
                        </tr>
                        {cont.attachement &&
                          cont.attachement
                            .filter((item) =>
                              item.firstName
                                ?.toLowerCase()
                                .includes(this.state.searchItem)
                            )
                            .map((item) => {
                              return (
                                <tr>
                                  <td className="userpron ">
                                    {item.firstName} {item.lastName}
                                  </td>
                                  <td className="userpron ">
                                    {item.numberOfShares}
                                  </td>
                                  <td className="userpron ">
                                    {" "}
                                    {numb2(item.sharesValue)}
                                  </td>
                                  <td className="userpron ">
                                    {item.numberOfMemberships}
                                  </td>
                                  <td>
                                    <p className="userinfonou">To pay</p>
                                    <p className="userinfowe">Paid</p>
                                  </td>

                                  <td>
                                    <p className="userinfonpadd">
                                      {numb2(
                                        item.attachment.Contribution && item.attachment.Contribution
                                          .capitaltopay
                                      )}
                                    </p>
                                    <p className="userinfowe">
                                      {" "}
                                      {numb2(
                                        item.attachment.Contribution && item.attachment.Contribution.capitalpaid
                                      )}
                                    </p>
                                  </td>

                                  {item.attachment.Contribution && item.attachment.Contribution.attachment
                                    .sort(function sort(a, b) {
                                      var aa = a.month.split("-"),
                                        bb = b.month.split("-");
                                      return aa[0] - bb[0] || aa[1] - bb[1];
                                    })
                                    .map((ctr) => {
                                      const vbalance =
                                        Number.parseFloat(ctr.capitaltopay) -
                                        Number.parseFloat(ctr.capitalpaid);
                                      //const zr=0;
                                      return vbalance < 0 ? (
                                        <td>
                                          <p className="userinfonou">
                                            {numb2(ctr.capitaltopay)}
                                          </p>

                                          <p
                                            className="userinfowe cursorPoint redColor"
                                            onClick={() =>
                                              this.openModal(
                                                ctr.itemid,
                                                "contribution",
                                                -vbalance
                                              )
                                            }
                                          >
                                            {numb2(ctr.capitalpaid)}
                                          </p>
                                        </td>
                                      ) : (
                                        <td>
                                          <p className="userinfonou">
                                            {numb2(ctr.capitaltopay)}
                                          </p>

                                          <p className="userinfowe">
                                            {numb2(ctr.capitalpaid)}
                                          </p>
                                        </td>
                                      );
                                    })}
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>

            {/* <>-----------------------------------------------------------------</> */}

            <Modal show={this.state.openModal} className="modalAll">
              <Modal.Body className="Madl">
                <div className="detmod">
                  <div>
                    <p className="closeModale" onClick={this.closeModal}>
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                  <div className=" conteinputitle ">
                    What action do you want to take?
                  </div>
                  <div className="d-flex">
                    <div className="conteinpu ml-3">
                      Select the action you would like to take
                      {/* <p className="unity"> Give Back</p>
                      <p className="unity">Convert</p> */}
                      <div class="form-group">
                        <label className="lebelenter">Amount (Rwf):</label>

                        <input
                          type="text"
                          id="actionamount"
                          name="actionamount"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.actionamount}
                          className="logininputsele"
                          placeholder="Enter amount"
                        />
                      </div>
                      <button
                        className="viewdetcli ml-3"
                        method="post"
                        onClick={(e) => this.takeAction(e, "giveback")}
                      >
                        Give Back
                      </button>
                      <button
                        className="viewdetcli ml-3"
                        method="post"
                        onClick={(e) => this.takeAction(e, "convert")}
                      >
                        Convert
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Contribution;
