import React, { Component } from "react";
import Navbar from "../../Navbar";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "../../SignOut";
import { link, goto } from "../../link.js";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import axios from "axios";
import Footer from "../../footer";
import { confirm } from "../../utils/Confirmation";
export class EditRound extends Component {
  constructor() {
    super();
    this.state = {
      round: {
        name: "hhhh",
        description: "",
        savingContributionAmount: "",
        membershipContributionAmount: "",
        shareValue: "",
        capitalDueDate: "",
        savingContributionStartDate: "",
        distributionStartDate: "",
        note: "",
      },

      startDate: new Date(),
      endDate: new Date(),
      loading: false,
      cansend: false,
      updateerror: false,
    };
    this.handleChangeDate = this.handleChangeDate(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    this.getRound();
  };

  getRound() {
    var token = localStorage.getItem("token");
    let path = window.location.hash;
    let roundId = path.split("/")[3];
    var config = {
      method: "get",
      url: link() + `&do=get&ra=round&ft=rec&elmt=roundID&id=${roundId}`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      }, timeout:30000
    };

    axios(config)
      .then((response) => {
        //if (Array.isArray(response.data)) {
        let round = response.data;

        round.savingContributionStartDate =
          response.data.savingContributionStartDate.toString().substring(0, 10);
        round.endDate = response.data.endDate.toString().substring(0, 10);
        round.startDate = response.data.startDate.toString().substring(0, 10);

        round.capitalDueDate = response.data.capitalDueDate
          .toString()
          .substring(0, 10);
        round.distributionStartDate = response.data.distributionStartDate
          .toString()
          .substring(0, 10);

        this.setState({
          round: round,
        });
      //  .console.log(response.data);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChangeDate(date) {
    this.setState({
      startDate: date,
    });
  }
  handleChange = (e) => {
    e.preventDefault();
    var round = this.state.round;
    round[e.target.name] = e.target.value;
    this.setState({ round });
  //  .console.log(round);
  };
  openModalSucess = () => {
    this.setState({
      openSuccess: true,
      updateerror: false,
    });
  };
  closeSuccess = () => {
    this.setState({
      openSuccess: false,
      updateerror: false,
    });
  };

  submitHandler = async (e) => {
    e.preventDefault();

    if (
      await confirm(
        "Making changes to an active round can have serious irreversible consequences.Do you want to continue?"
      )
    ) {
      this.setState({
        cansend: true,
      });
    } else {
      this.setState({
        cansend: false,
      });
    }

  //  .console.log("Cansend=" + this.state.cansend);

    if (!this.state.cansend) return;
    this.setState({
      loading: true,
      updateerror: false,
    });
    var round = this.state.round;

    var token = localStorage.getItem("token");
    var config = {
      method: "post",

      url: link() + `&do=update&ra=round`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: round,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);

        if (response.data === "Success") goto("Main/RoundInfo");
        else this.setState({ updateerror: true });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ updateerror: true });
      });

    this.setState({
      openSuccess: true,
      loading: false,
    });
  };

  render() {
    const { startDate, loading } = this.state;

    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">Round Management</h6>
        </div>

        <h6 className="activeuser"> Edit Round Information</h6>
        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className="rowuno mt-3">
              <div class="form-group">
                <label className="lebelenter">Round Name</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.name}
                  className="logininputs"
                  placeholder="Name  or round:"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Description</label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  //required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.description}
                  className="logininputs"
                  placeholder="Description :"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Value of a Share</label>
                <input
                  type="number"
                  id="param2"
                  name="shareValue"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.shareValue}
                  className="logininputs"
                  placeholder="shareValue:"
                />
              </div>
            </div>
            <div className="rowuno">
              <div class="form-group">
                <label className="lebelenter">
                  Value of Round Payment Share
                </label>
                <input
                  type="number"
                  id="param2"
                  required
                  name="membershipContributionAmount"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.membershipContributionAmount}
                  className="logininputs"
                  placeholder="Payable Round Amount:"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">
                  Value of Monthly Reserve Share
                </label>
                <input
                  type="number"
                  id="savingAmount"
                  required
                  name="savingContributionAmount"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.savingContributionAmount}
                  className="logininputs"
                  placeholder="Monthly Reserve Amount: "
                />
              </div>

              <div class="form-group">
                <label className="lebelenter">
                  Social Capital Contribution Due Date
                </label>
                {/* <DatePicker
                  selected={this.state.startDate}
                  // onChange={(e) => this.handleChangeDate(e)}
                  onChange={this.handleChangeDate}
                  name="transactionDate"
                  //maxDate={this.state.endDate}
                  className="logininputs"
                  dateFormat="yyyy-MM-dd"
                  placeholder="Pick a date"
                /> */}
                <input
                  type="text"
                  id="uname"
                  name="capitalDueDate"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.capitalDueDate}
                  className="logininputs"
                  placeholder="Enter Date: YYYY-MM-DD"
                />
              </div>
            </div>
            <div className="rowuno">
              {/* <div class="form-group">
                <input
                  type="text"
                  id="param2"
                  name="distributionStartDate"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.distributionStartDate}
                  className="logininputs"
                  placeholder="distributionStartDate:"
                />
              </div> */}
              <div class="form-group">
                <label className="lebelenter">Round Start Date</label>
                <input
                  type="text"
                  id="uname"
                  name="startDate"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.startDate}
                  className="logininputs"
                  placeholder="Round Start Date: YYYY-MM-DD"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">Round End Date</label>
                <input
                  type="text"
                  id="param2"
                  name="endDate"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.endDate}
                  className="logininputs"
                  placeholder="Round End Date: YYYY-MM-DD"
                />
              </div>
              <div class="form-group">
                <label className="lebelenter">
                  Reserve Contribution Start Date
                </label>
                <input
                  type="text"
                  id="param2"
                  name="savingContributionStartDate"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.savingContributionStartDate}
                  className="logininputs"
                  placeholder="Reserve contribution start date: YYYY-MM-DD"
                />
              </div>
            </div>
            <div className="rowuno">
              <div class="form-group">
                <label className="lebelenter">Note to approvers</label>
                <input
                  type="text"
                  id="param2"
                  name="note"
                  //required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.round.note}
                  className="logininputs"
                  placeholder="note to approvers: "
                />
              </div>
            </div>
            <div className="rowuno"></div>

            <button type="submit" value="Save" className="viewdetcli ml-5">
              {!loading && <span className="viewdetcli ">Save</span>}
              {loading && <span className="viewdetcli ">Loading ...</span>}
            </button>
            <br />
          </form>
        </div>
        <div className="schoolModal">
          <Modal show={this.state.openSuccess} className="modalAll">
            <Modal.Body className="Madl">
              <div>
                <p className="btnaddblog" onClick={this.closeSuccess}>
                  <i className="fas fa-times"></i>
                </p>
              </div>
              <div>
                <p className="sent">Updating Round information </p>
                {this.state.updateerror ? (
                  <p className="succpar">
                    Something went wrong. See if all information is correct and
                    try again later!
                  </p>
                ) : (
                  <p className="succpar">Wait...!</p>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default EditRound;
