export function link() {
  return linkbasic() + "link=55";
}
export function linkbasic() {
  // var prefix = "ikimina/api?";
  // if (process.env.REACT_APP_HOST_ENV === "TEST")
  //   return process.env.REACT_APP_TEST_API_URL + prefix;

  // if (process.env.REACT_APP_HOST_ENV === "PRO")
  //   return process.env.REACT_APP_PRO_API_URL + prefix;
  // if (process.env.REACT_APP_HOST_ENV === "LOCTEST")
  //   return process.env.REACT_APP_LOCTEST_API_URL + prefix;

  return api() + "ikimina/api?";
}
// REACT_APP_PRO_API_URL="https://be.its-mis.com/"

export function api() {
  // var prefix = "ikimina/api?";
  if (process.env.REACT_APP_HOST_ENV === "TEST")
    return process.env.REACT_APP_TEST_API_URL;

  if (process.env.REACT_APP_HOST_ENV === "PRO")
    return process.env.REACT_APP_PRO_API_URL;
  if (process.env.REACT_APP_HOST_ENV === "LOCTEST")
    return process.env.REACT_APP_LOCTEST_API_URL;

  return "http://localhost:8085/";
}

export function goto(link) {
  // window.location.href =
  //   window.location.pathname + "?u=" + Math.random() + "#/" + link;

  console.log(window.location.pathname);
  window.location.href = window.location.pathname + "#/" + link;
}

export function numb2(number) {
  //console.log("Number", number2);
  if (number === undefined || number === "" || number === null) return 0;
  var number2 = parseFloat(number);
  //console.log("Number", number2);
  return number2.toLocaleString(undefined, { maximumFractionDigits: 2 });
}

export function gotopage(type, id) {
  var link = "";
  if (type === "round") link = "Main/RoundInfo/" + id;
  if (type === "loan") link = "Main/LoanDetails/" + id;
  if (type === "convert") link = "Main/Convert/" + id;

  //if (link !== "") window.location.href = window.location + link + id;
  window.location.href = window.location.pathname + "#/" + link;
}

export async function downloadFile(tmethod, url, data, headers,title) {
  // Change this to use your HTTP client
  fetch(url, {
    method: tmethod,
    headers: headers,
    body: JSON.stringify(data),
    responseType: "blob",
  }) // FETCH BLOB FROM IT
    .then((response) => response.blob())
    .then((response) => {
      // RETRIEVE THE BLOB AND CREATE LOCAL URL

      const pdfBlob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const blobUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", title);
      link.click();
      link.remove();
      URL.revokeObjectURL(blobUrl);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function downloadFile1(tmethod, url, data, headers) {
  // Change this to use your HTTP client
  fetch(url, {
    method: tmethod,
    headers: headers,
    body: JSON.stringify(data),
  }) // FETCH BLOB FROM IT
    .then((response) => response.blob())
    .then((response) => {
      // RETRIEVE THE BLOB AND CREATE LOCAL URL
      const pdfBlob = new Blob([response], "report.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var _url = window.URL.createObjectURL(pdfBlob);
      window.open(_url, "_blank").focus(); // window.open + focus
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function downloadFile2(tmethod, url, data, headers) {
  // Change this to use your HTTP client
  fetch(url, {
    method: tmethod,
    headers: headers,
    body: JSON.stringify(data),
  }) // FETCH BLOB FROM IT
    .then((response) => response.blob())
    .then((response) => {
      // RETRIEVE THE BLOB AND CREATE LOCAL URL
      // const pdfBlob = new Blob([response], "report.xlsx", {
      //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // });
      // var _url = window.URL.createObjectURL(pdfBlob);
      // window.open(_url, "_blank").focus(); // window.open + focus

      //IE11 support
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        let blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        window.navigator.msSaveOrOpenBlob(blob, "report.xlsx");
      } else {
        // other browsers
        let file = new File([response], "report.xlsx", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let exportUrl = URL.createObjectURL(file);
        window.location.assign(exportUrl);
        URL.revokeObjectURL(exportUrl);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function sendDownloadPost(method, url, data, target) {
  console.log("Data is " + data);
  var form = document.createElement("form");
  form.action = url;
  form.method = method;
  form.target = target || "_self";
  if (data) {
    for (var key in data) {
      var input = document.createElement("textarea");
      input.name = key;
      console.log("input name =" + input.name);
      input.value = data[key];
      form.appendChild(input);
      console.log("input value =" + input.value);
    }
  }
  form.style.display = "none";
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export function wordContains(searchable, searchkey) {
  searchkey = searchkey.trim();
  if (searchkey === "") return true;
  //if(searchable==="") return false;
  var contains = true;
  for (var key of searchkey.split(",")) {
    for (var newkey of key.trim().split(" ")) {
      // console.log("Searching '" + searchable + "' s key '" + newkey + "' ");
      if (!searchable.includes(newkey) && newkey.trim() !== "") {
        // console.log(
        //   "Sorry '" + searchable + "' doesn't include '" + newkey + "' "
        // );
        contains = false;
      }
    }
  }
  // console.log(
  //   "Search results '" + searchable + "'-'" + searchkey + "' is " + contains
  // );
  return contains;
}
