import React, { Component } from "react";
import Navbar from "../Navbar";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import SignOut from "../SignOut";
import { link, goto } from "../link.js";
import DatePicker from "react-datepicker";
import Success from "../success";
import Footer from "../footer";
import { format } from "date-fns";
export class AddAsset extends Component {
  constructor() {
    super();
    this.state = {
      asset: {
        name: "",
        nameOther: "",
        description: "",
        assetCategory: "Asset",
        type: "",
        purchaseValue: "",
        currentValue: "",
        purchaseDate: "",
        round: "",
      },
      openSucess: false,
      openError: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  openModalSucess = () => {
    this.setState({
      openSucess: true,
    });
  };
  closeSuucess = () => {
    //window.location.href = window.location + "Main/AddMember";

    goto("Main/Assets");
  };
  // openModalError = () => {
  //   this.setState({
  //     openError: true,
  //   });
  // };
  closeError = () => {
    //window.location.href = window.location + "Main/Signin";
    goto("Main/Signin");
  };
  handleChange = (e) => {
    let asset = this.state.asset;
    asset[e.target.name] = e.target.value;
    this.setState({ asset });

  //  .console.log(e.target.name + " = " + e.target.value);
  };
  handleChangeDate = (item) => {
    //e.preventDefault();
    //console.log(item);
    let asset = this.state.asset;
    asset[item.name] = item.value;
    this.setState({ asset });

  //  .console.log(this.state.asset);
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const { asset } = this.state;
    var newAsset = JSON.stringify({
      name: asset.name,
      nameOther: asset.nameOther,
      description: asset.description,
      assetCategory: asset.assetCategory,
      type: asset.type,
      purchaseValue: asset.purchaseValue,
      currentValue: asset.currentValue,
      purchaseDate: format(Date.parse(asset.purchaseDate), "yyyy-MM-dd"),
      round: asset.round,
    });
  //  .console.log("to save=" + newAsset);
    var token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: link() + `&do=create&ra=asset`,
      headers: {
        Authorization: `${token}`,

        "Content-Type": "application/json",
      },
      data: newAsset, timeout: 5000,
    };

    axios(config)
      .then((response) => {
      //  .console.log(response.data);
        this.setState({
          openSucess: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { loading, asset } = this.state;
  //  .console.log(this.state.asset);
    return (
      <div className="contentdashbord">
        <Navbar />
        <SignOut />
        <div className="welcome ">
          <h6 className="activejobs">Asset</h6>
        </div>

        <h6 className="activeuser"> Add an asset</h6>
        <div className=" cardtable">
          <form
            className="formcreate "
            method="post"
            onSubmit={(e) => this.submitHandler(e)}
          >
            <div className="rowuno mt-3">
              <div clasName="d-block">
                <label className="lebelenter">Asset Category:</label>
                <div class="form-group">
                  <select
                    id="reporttype"
                    type="text"
                    name="assetCategory"
                    id="validationDefault02"
                    required
                    onChange={(e) => this.handleChange(e)}
                    selected={asset.assetCategory}
                    className="form-select  logininputsselect"
                    placeholder="Select Bank type"
                  >
                    <option value="">Select Asset Category</option>
                    <option value="Investment">Investment</option>
                    <option value="Asset">Other Asset</option>
                  </select>
                </div>
              </div>
              <div clasName="d-block">
                <label className="lebelenter">Name</label>
                <div class="form-group">
                  <input
                    id="name"
                    type="text"
                    name="name"
                    id="validationDefault03"
                    required
                    onChange={(e) => this.handleChange(e)}
                    value={asset.name}
                    className="logininputs"
                  />
                </div>
              </div>
              <div clasName="d-block">
                <label className="lebelenter">Type</label>
                <div class="form-group">
                  <input
                    id="type"
                    type="text"
                    name="type"
                    required
                    onChange={(e) => this.handleChange(e)}
                    value={asset.type}
                    className="logininputs"
                    placeholder="Lapttop, Investment, Phone, etc"
                  />
                </div>
              </div>
            </div>
            <div className="rowuno">
              <div clasName="d-block">
                <label className="lebelenter">Asset Value (Rwf)</label>
                <div class="form-group">
                  <input
                    type="number"
                    id="purchaseValue"
                    name="purchaseValue"
                    id="validationDefault01"
                    required
                    onChange={(e) => this.handleChange(e)}
                    value={asset.purchaseValue}
                    className="logininputs"
                  />
                </div>
              </div>
              <div clasName="d-block">
                <label className="lebelenter">Puchase Date</label>

                <DatePicker
                  selected={asset.purchaseDate}
                  onChange={(date) =>
                    this.handleChangeDate({
                      name: "purchaseDate",
                      value: date,
                    })
                  }
                  //onChange={this.handleChangeDate}
                  name="purchaseDate"
                  //maxDate={this.state.endDate}
                  className="logininputsele"
                  dateFormat="yyyy-MM-dd"
                  id="validationDefault01"
                  required
                  placeholderText="Pick a date"
                />
              </div>

              <div clasName="d-block">
                <label className="lebelenter">Round</label>
                <div class="form-group">
                  <select
                    id="round"
                    type="text"
                    name="round"
                    required
                    onChange={(e) => this.handleChange(e)}
                    selected={asset.assetCategory}
                    className="form-select  logininputsselect"
                    placeholder="Select Bank type"
                  >
                    <option value="">Select Associated Round</option>
                    <option value="Other">Other Round</option>
                    <option value="Current">Current Round</option>
                  </select>
                </div>
              </div>
            </div>

            <div clasName="d-block">
              <label className="lebelenter">Description</label>
              <div class="form-group">
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  onChange={(e) => this.handleChange(e)}
                  value={asset.description}
                  className="parainputsmestext ml-3"
                />
              </div>
            </div>

            <button type="submit" value="Save" className="viewdetcli ml-5">
              {!loading && <span className="viewdetcli ">Save</span>}
              {loading && <span className="viewdetcli ">Loading ...</span>}
            </button>

            <br />
          </form>
        </div>
        <div className="schoolModal">
          <Modal show={this.state.openSucess} className="modalAll">
            <Modal.Body className="Madl">
              <div>
                <p className="btnaddblog" onClick={this.closeSuucess}>
                  <i className="fas fa-times"></i>
                </p>
              </div>
              <div>
                <p className="sent">Successfully added!</p>
                <p className="succpar">
                  You have successfully added an asset. Congrats!
                </p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default AddAsset;
