import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../../Auth";
import Navbar from "../../Navbar";
import SignOut from "../../SignOut";
import { link, gotopage, goto, numb2 } from "../../link.js";
import axios from "axios";
import Footer from "../../footer";
import Loading from "../../loading";
export class Capital extends Component {
  constructor() {
    super();
    this.state = {
      cont: [],
      sums: [],
      searchItem: "",
      totalshares: 0,
      valueshares: 0,
      loading: false,
      roundid: "",
      action: "",
      actionid: "",
      actionon: "",
      actionamount: 0,
    };
  }
  // handleCreateRound = () => {
  //   window.location.href = window.location + "Main/CreateRound";
  // };
  // handleEditRond = () => {
  //   window.location.href = window.location + "Main/EditRound";
  // };
  // handleMigrateRond = () => {
  //   window.location.href = window.location + "Main/MigrateRound";
  // };
  handleContribution = () => {
    goto("Main/Contribution/" + this.state.roundid);
  };
  handleDistribution = () => {
    goto("Main/Distribution/" + this.state.roundid);
  };
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getContribution();
  };
  getContribution() {
    var token = localStorage.getItem("token");
    let path = window.location.hash;
    let roundId = path.split("/")[3] || "";

    this.setState({ roundid: roundId });
    var config = {
      method: "post",
      url:
        link() +
        `&do=getcontributions&ra=round&ft=rec&elmt=roundID&id=` +
        roundId,
      headers: {
        Authorization: `${token}`,
      },
      timeout: 30000
    };

    axios(config)
      .then((response) => {
        const sums = [];
        let ytdtopay = 0;
        let ytdpaid = 0;
        let totalshares = 0;
        let valueshares = 0;
        let totalPenalities = 0;
        response.data.attachement.forEach((item, indx) => {
          item.attachment.Capital && item.attachment.Capital.attachment.forEach((itm, index) => {
            if (sums[index]) {
              sums.splice(index, 1, {
                capitaltopay:
                  Number.parseFloat(sums[index].capitaltopay) +
                  Number.parseFloat(itm.capitaltopay),
                capitalpaid:
                  Number.parseFloat(sums[index].capitalpaid) +
                  Number.parseFloat(itm.capitalpaid),
                capitalbalance:
                  Number.parseFloat(sums[index].capitalbalance) +
                  Number.parseFloat(itm.capitalbalance),

                penaltybalance:
                  Number.parseFloat(sums[index].penaltybalance) +
                  Number.parseFloat(itm.penaltybalance),
                penaltytopay:
                  Number.parseFloat(sums[index].penaltytopay) +
                  Number.parseFloat(itm.penaltytopay),

                penaltypaid:
                  Number.parseFloat(sums[index].penaltybalance) +
                  Number.parseFloat(itm.penaltybalance),
              });
            } else {
              sums.push({
                penaltypaid: Number.parseFloat(itm.penaltypaid),
                penaltytopay: Number.parseFloat(itm.penaltytopay),
                penaltybalance: Number.parseFloat(itm.penaltybalance),
                capitalpaid: Number.parseFloat(itm.capitalpaid),
                capitalbalance: Number.parseFloat(itm.capitalbalance),
                capitaltopay: Number.parseFloat(itm.capitaltopay),
              });
            }
          });

          valueshares =
            Number.parseFloat(valueshares) +
            Number.parseFloat(item.sharesValue);
          totalshares =
            Number.parseFloat(totalshares) +
            Number.parseFloat(item.numberOfShares);
        });
        //console.log(sums);
        this.setState({
          cont: response.data,
          sums,
          totalshares,
          valueshares,
          roundid: response.data.roundID,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'ECONNABORTED') {
          alert("Server connection timed out");
      } else {
       // alert("Error occured");
      }
        this.setState({
          loading: false,
        });
      });
  }

  handleChange = (e) => {
    //let item = this.state.e.target.name;
    // users[e.target.name] = e.target.value;
    this.setState({ actionamount: e.target.value });
  };

  takeAction(e, action, amount) {
    e.preventDefault();

    if (action === "convert")
      gotopage(
        "convert",
        action +
          this.state.action +
          "/" +
          this.state.actionon +
          "/" +
          this.state.actionid +
          "/" +
          this.state.actionamount
      );

    if (action === "giveback") {
      var token = localStorage.getItem("token");
      var config = {
        method: "post",
        url:
          link() +
          `&do=${action}&ra=spending&elmt=${this.state.actionon}&amount=${this.state.actionamount}&id=` +
          this.state.actionid,
        headers: {
          Authorization: `${token}`,
        },
        timeout: 30000
      };

      axios(config)
        .then((response) => {
        //  console.log("Response for giveback=");
        //  console.log(response.data);
          this.closeModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };

  openModal = (id, actiononitem, amount) => {
  //  console.log("Modal Opened with id " + id);
    this.setState({
      openModal: true,
      actionid: id,
      action: "",
      actionon: actiononitem,
      actionamount: amount,
    });
  };
  closeModal = () => {
    this.setState({
      openModal: false,
      actionid: "",
      action: "",
      actionon: "",
      actionamount: 0,
    });
  };
  render() {
    const {
      cont,
      sums,
      totalshares,
      valueshares,
      totalPenalities,
      roundid,
      loading,
    } = this.state;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Contributions</h6>
            <div className="searchadd">
              <button className="btndistr" onClick={this.handleContribution}>
                Contribution
              </button>
              <button
                className="btndistr"
                onClick={this.handleDistribution}
                disabled={loading}
              >
                Round Payment
              </button>
              {/* <input
                type="text"
                id="search"
                className="loginsearchfa"
                placeholder=" Search a Round here…
                "
              /> */}
            </div>
            <div className="activeuser">
              <div class="row ">
                <div class=" col-lg-3">
                  <div className="col conten">
                    Round Name:
                    
                    <span className="unity">{cont.name}</span>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div className="col conten">
                    Start Date:
                    <span className="unity">
                      {cont.startDate
                        ? cont.startDate.toString().substring(0, 10)
                        : ""}
                    </span>
                  </div>
                </div>
                <div class="col col-lg-3">
                  <div className="col conten">
                    End Date:
                    <span className="unity">
                      {" "}
                      {cont.endDate
                        ? cont.endDate.toString().substring(0, 10)
                        : ""}
                    </span>
                  </div>
                </div>
                <div class="col col-lg-3">
                  <div className="col conten">
                    Current Month:
                    <span className="unity"> </span>
                  </div>
                </div>
                <div class="col col-lg-3.5">
                  <div className="col conten">
                    Number of Members:
                    <span className="unity">
                      {cont.attachement && cont.attachement.length}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailedcon ">
              <Link to={"/Main/Contribution/" + roundid} className="contrilink">
                Contribution
              </Link>
              <Link to={"/Main/Capital/" + roundid} className="contrilink">
                Capital
              </Link>
              <Link to={"/Main/Penalties/" + roundid} className="contrilink">
                Penalties
              </Link>
              <Link to={"/Main/Commissions/" + roundid} className="contrilink">
                Commissions
              </Link>
            </div>
            <div className="cardtable ">
              <div className="containeruserscroll">
                <div className="membadd">
                  <p className=" contentmemb">Capital Contributions</p>
                  <input
                    type="text"
                    id="search"
                    className="loginsearchfaro"
                    placeholder=" Search a name..."
                    onChange={(e) => this.handleUserChange(e)}
                  />
                  {/* <input
                  type="text"
                  id="search"
                  className="loginsearchfaro"
                  placeholder=" Search a name...
                "
                />
                <div
                  className="btnaddblogadds  "
                  onClick={this.handlememberRond}
                >
                  <i class="fas fa-plus"></i>
                </div> */}
                </div>
                <div className="cardtablecapital">
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <table className="table table-hover  table-responsive   ">
                        <thead>
                          <tr>
                            <th className="userpronou ">Names</th>
                            <th className="userpronou ">Shares</th>
                            {/* <th className="userpronou "></th> */}
                            <th className="userpronou ">Capital Value</th>
                            <th className="userpronou ">Due Date</th>
                            <th className="userpronou ">Paid Date</th>
                            <th className="userpronou ">Paid Capital</th>

                            <th className="userpronou "> Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="userprony "></td>
                            <td className="userprony "> {totalshares}</td>
                            {/* <td className="userprony ">Contributions</td> */}
                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userprony ">
                                    {numb2(totalpay.capitaltopay)}
                                  </td>
                                );
                              })}
                            <td className="userprony "></td>
                            <td className="userprony "></td>
                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userprony ">
                                    {numb2(totalpay.capitalpaid)}
                                  </td>
                                );
                              })}

                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userprony ">
                                    {numb2(totalpay.capitalbalance)}
                                  </td>
                                );
                              })}
                          </tr>
                          {/* <tr>
                            <td className="userpronouwe "></td>
                            <td className="userpronouwe "></td>
                            <td className="userpronouwe ">Penalties</td>
                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userpronouwe ">
                                    {numb2(totalpay.penaltytopay)}
                                  </td>
                                );
                              })}
                            <td className="userpronouwe "></td>
                            <td className="userpronouwe "></td>
                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userpronouwe ">
                                    {numb2(totalpay.penaltypaid)}
                                  </td>
                                );
                              })}

                            {sums &&
                              sums.map((totalpay) => {
                                return (
                                  <td className="userpronouwe ">
                                    {numb2(totalpay.penaltybalance)}
                                  </td>
                                );
                              })}
                          </tr> */}

                          {cont.attachement &&
                            cont.attachement
                              .filter((item) =>
                                item.firstName
                                  ?.toLowerCase()
                                  .includes(this.state.searchItem)
                              )
                              .map((item) => {
                                
                                return (
                                  <tr>
                                    <td className="userpron ">
                                      {item.firstName} {item.lastName}
                                    </td>
                                    <td className="userpron ">
                                      { numb2(item.numberOfShares)}
                                    </td>
                                    {/* <td className="userpron ">
                                      <p className="usercapitalonou">
                                        Share Value{" "}
                                      </p>
                                      <p className="usercapitalwe">Penalties</p>
                                    </td> */}

                                    {item.attachment.Capital? item.attachment.Capital.attachment.map(
                                      (cap) => {
                                        var vbalance = Number.parseFloat(
                                          cap.penaltybalance
                                        );
                                        var vcbalance = Number.parseFloat(
                                          cap.capitalbalance
                                        );
                                        return (
                                          <>
                                            <td className="userpron ">
                                              {/* <p className="usercapitalonou"> */}
                                              {numb2(cap.capitaltopay)}
                                              {/* </p>

                                              <p className="usercapitalwe">
                                                {numb2(cap.penaltytopay)}
                                              </p> */}
                                            </td>

                                            <td className="userpron ">
                                              {/* <p className="usercapitalwe"> */}
                                              {cap.duedate
                                                ? cap.duedate
                                                    .toString()
                                                    .substring(0, 10)
                                                : ""}
                                              {/* </p>
                                              <p className="usercapitalwe"> </p> */}
                                            </td>
                                            <td className="userpronou ">
                                              {/* <p className="userinfonpadd"> */}
                                              {cap.paiddate
                                                ? cap.paiddate
                                                    .toString()
                                                    .substring(0, 10)
                                                : ""}
                                              {/* </p>
                                              <p className="userinfowe">
                                                {cap.penaltypaiddate
                                                  ? cap.penaltypaiddate
                                                      .toString()
                                                      .substring(0, 10)
                                                  : ""}
                                              </p> */}
                                            </td>
                                            <td>
                                              <p className="userinfonpadd">
                                                {numb2(cap.capitalpaid)}
                                              </p>
                                              {/* <p className="userinfowe">
                                                {numb2(cap.penaltypaid)}
                                              </p> */}
                                            </td>

                                            <td>
                                              {vcbalance > 0 ? (
                                                <p
                                                  className="userinfonpadd  redColor cursorPoint"
                                                  onClick={() =>
                                                    this.openModal(
                                                      cap.itemid,
                                                      "contribution",
                                                      vcbalance
                                                    )
                                                  }
                                                >
                                                  {numb2(cap.capitalbalance)}
                                                </p>
                                              ) : (
                                                <p className="userinfonpadd">
                                                  {numb2(cap.capitalbalance)}
                                                </p>
                                              )}

                                              {/* {vbalance > 0 ? (
                                                <p
                                                  className="userinfowe redColor cursorPoint"
                                                  onClick={() =>
                                                    this.openModal(
                                                      cap.itemid,
                                                      "contribution",
                                                      vbalance
                                                    )
                                                  }
                                                >
                                                  {numb2(cap.penaltybalance)}
                                                </p>
                                              ) : (
                                                <p className="userinfowe ">
                                                  {numb2(cap.penaltybalance)}
                                                </p>
                                              )} */}
                                            </td>
                                          </>
                                        );
                                      }
                                    ) : <td colSpan="5"></td>}
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>

            <br />
            {/* <>-----------------------------------------------------------------</> */}

            <Modal show={this.state.openModal} className="modalAll">
              <Modal.Body className="Madl">
                <div className="detmod">
                  <div>
                    <p className="closeModale" onClick={this.closeModal}>
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                  <div className=" conteinputitle ">
                    What action do you want to take?
                  </div>
                  <div className="d-flex">
                    <div className="conteinpu ml-3">
                      Select the action you would like to take
                      {/* <p className="unity"> Give Back</p>
                      <p className="unity">Convert</p> */}
                      <div class="form-group">
                        <label className="lebelenter">Amount (Rwf):</label>

                        <input
                          type="text"
                          id="actionamount"
                          name="actionamount"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.actionamount}
                          className="logininputsele"
                          placeholder="Enter amount"
                        />
                      </div>
                      <button
                        className="viewdetcli ml-3"
                        method="post"
                        onClick={(e) => this.takeAction(e, "giveback")}
                      >
                        Give Back
                      </button>
                      <button
                        className="viewdetcli ml-3"
                        method="post"
                        onClick={(e) => this.takeAction(e, "convert")}
                      >
                        Convert
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Capital;
