import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Modal,
  Button,
  Col,
  Row,
  Carousel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "../Auth";
import { linkbasic, goto } from "./link.js";
import axios from "axios";
import Footer from "./footer";
import packageJson from "../../package.json";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      blocker: [],
      isitLoading: false,
      openModal: false,
      message: "",
      showitems: true,
      loading: false,
    };

    // Check if user is logged in and redirect to the main page
    if (Auth.getAuth()) {
      this.goToDashboard();
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.login = this.login.bind(this);
  }
  goToDashboard() {
    let query = window.location.href.split("?")[2];
    if (query) {
      window.location.replace("/");
    } else {
      if (localStorage.getItem("currentView") == "grid") {
        window.location.replace("#/Main/Overall");
        //window.location.href = window.location + "/Main/Overall";
      } else {
        window.location.replace("#/Main/Overall");
        //window.location.href = window.location.host + "/Main/Overall";
      }
    }
  }
  // componentDidMount() {
  //   this.login();
  // }
  login() {
    Auth.authenticate();

    var axios = require("axios");
    // var data = JSON.stringify({});

    var config = {
      method: "post",
      url:
        linkbasic() +
        // `view=login&oid=${this.state.input["email"]}&jmb=` +
        // this.state.input["password"],
        `view=login&`,
      headers: {},
      data: {
        oid: this.state.input["email"],
        jmb: this.state.input["password"],
      },timeout: 30000,
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        this.setState({
          loading: false,
        });
        if (response.data.length > 0 || response != "AUTH Error") {
          localStorage.setItem("token", response.data.t);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("version", packageJson.version);
          localStorage.setItem("engine", response.data.e);
          localStorage.setItem("rcd", new Date());
        }

        if (response.data == "AUTH Error") {
          window.location.replace("/");
        } else {
          this.goToDashboard();
        }
      })
      .catch((error) => {
        console.log(error);

        this.setState({
          loading: false,
        });

        if (error.code === 'ECONNABORTED') {
          console.error('API call timed out');
          alert("Server connection timed out");
      } else {
        alert("Please enter correct credetials");
      }
        
      });
  }

  prereset = (e) => {
    e.preventDefault();
    //Auth.authenticate();

    this.setState({
      message: "Sending.",
      showitems: false,
    });

    var axios = require("axios");
    // var data = JSON.stringify({});

    var config = {
      method: "post",
      url:
        linkbasic() +
        `view=login&action=setreset&email=${this.state.input["email"]}&wtg=` +
        window.location.origin +
        window.location.pathname +
        `&wtj=` +
        "/Reset/",
      headers: {},
      data: {
        //wtg: window.location + "#/" + "Main/reset/",
        
      },timeout: 20000,
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        if (response.data === "Success" || response.data === "OK") {
          this.setState({
            message:
              "A link to reset password has been sent to your email inbox. You can close this window now.",
          });
        } else {
          this.setState({
            message: "Something went wrong. Try again later. "+response.data,
          });
        }
        //else goto("Main/reset/" + response.data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          message: "Something went wrong. Try again later. "+error.message,
        });
      });

    //this.closeModal();
  };

  handleChange(e) {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({
      input,
    });
  }
  handleSubmit(e) {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let input = {};
    input["email"] = "";
    input["password"] = "";
    this.setState({ input: input });

    let validLogin = this.validate();
    if (validLogin) {
      this.login();
    }
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (typeof input["email"] !== undefined) {
      // var pattern = new RegExp(/^\S+@\S+\.\S+$/);
      // if (!pattern.test(input["email"])) {
      //   isValid = false;
      //   errors["email"] = "The User name field is required.";
      // }
    }
    if (typeof input["password"] !== undefined) {
      // var patternPass = new RegExp("^[a-zA-Z0-9_]*$");
      // if (!patternPass.test(input["password"])) {
      //   isValid = false;
      //   errors["password"] = "The Password field is required.";
      // }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  closeModal = () => {
    this.setState({
      openModal: false,
      input: {},
      message: "",
      showitems: true,
    });
  };

  openModal = () => {
    this.setState({
      openModal: true,
      input: {},
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <header
          id="header"
          className="fixed-top d-flex align-items-center  header-transparent "
        >
          <Navbar expand="lg" className="Nav__black">
            <Navbar.Brand>
              <Link to="/" className="logo">
                <p className="logoparag">IKIMINA TOWARDS THE SUCCESS</p>
              </Link>
            </Navbar.Brand>
          </Navbar>
        </header>
        <div className="login">
          <div className="caerdlog">
            <h1 className="tslog">Log In</h1>
            <form
              onSubmit={this.handleSubmit}
              className="formdata mt-5"
              method="POST"
            >
              <div className="form-group ">
                <label className="lebelenterlog">Email </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  required
                  value={this.state.input.email}
                  onChange={this.handleChange}
                  className="formModaltes"
                  placeholder="Enter email"
                />
              </div>
              <div className="dangerdemor">{this.state.errors.email}</div>

              <div className="form-group forminput">
                <label className="lebelenterlog"> Password </label>
                <input
                  type="password"
                  name="password"
                  required
                  id="validationDefault01"
                  value={this.state.input.password}
                  onChange={this.handleChange}
                  className="formModaltes"
                  placeholder="Enter password"
                />
              </div>

              <p
                className="need-help tds-text--center"
                onClick={this.openModal}
              >
                Forgot password?
              </p>
              <span
                class="linebreak tds-text--500"
                data-i18n-key="pageSeparatorOrLabel"
              >
                Or
              </span>
              <button type="submit" value="Login" className="btnapplsign">
                {!loading && <span className="btnapplsign ">Login</span>}
                {loading && <span className="btnapplsign ">Loading ...</span>}
              </button>

              {/* <input type="submit" className="" /> */}
              <br />
              {/* <div className="forgotpassword" onClick={this.openModal}>
                <Link className="forgotpassword">
                  {" "}
                  Forgot password{" "}
                  <span className="ml-3 mt-1">
                    <i class="fas fa-arrow-right"></i>
                  </span>
                </Link>
              </div> */}
            </form>
          </div>
          <Modal show={this.state.openModal} className="modalAll">
            <Modal.Body className="Madl">
              <div className="detmod">
                <div>
                  <p className="closeModale" onClick={this.closeModal}>
                    <i className="fas fa-times"></i>
                  </p>
                </div>
                <div className=" conteinputitle ">Provide your email</div>
                {this.state.showitems ? (
                  <div>
                    <div className=" mt-4" hide={this.state.showitems}>
                      <div class="form-group">
                        <div>
                          <label className="lebelenterlog">Email </label>
                        </div>

                        <input
                          type="text"
                          //id="email"
                          name="email"
                          required
                          value={this.state.input.email}
                          onChange={this.handleChange}
                          className="formModaltes"
                          placeholder="Enter your email address"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-block   ">
                        <p>{this.state.errormessage}</p>
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col-6">
                            <button
                              className="btnapplsign mt-4"
                              method="post"
                              onClick={(e) => this.prereset(e)}
                            >
                              Next
                            </button>
                          </div>
                          <div className="col-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.showitems ? (
                  ""
                ) : (
                  <div>
                    <div className="d-block  ml-5">
                      <p className="lebelenterlog">{this.state.message}</p>
                      <div className="row">
                        <div className="col-3"></div>
                        <div className="col-6">
                          <button
                            className="viewdetcli"
                            method="post"
                            onClick={this.closeModal}
                          >
                            Close
                          </button>
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Login;
