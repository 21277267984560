import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../Auth";
import Navbar from "../Navbar";
import axios from "axios";
import SignOut from "../SignOut";
import { link, goto } from "../link.js";
import Footer from "../footer";
import Loading from "../loading";
export class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member: [],
      isLoading: false,
      searchItem: "",
      loading: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      isLoading: true,
      loading: true,
    });
    this.getMembers();
  };

  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  getMembers() {
    var token = localStorage.getItem("token");
    var postData = {};
    var headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    localStorage.setItem("currentView", "faculty");
    axios
      .post(link() + `&do=get&ra=user&ft=all`, postData, { headers: headers , timeout: 30000})
      .then((res) => {
      //  .console.log("RESPONSE RECEIVED: ", res);
        var member = this.state.member;

        if (Array.isArray(res.data)) member = res.data;
        setTimeout(() => {
          this.setState({
            member: member,
            isLoading: false,
            loading: false,
          });
        }, 1000);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          loading: false,
        });
        console.log("AXIOS ERROR: ", err);
      });
  }

  // Jornalism = (id) => {
  //   window.location.href = window.location + "Main/Departments/" + id;
  //    "Main/EditMember" + "/" + id
  // };
  // handleClick = (id) => {
  //   this.Jornalism(id);
  // };
  handleAdd = () => {
    //window.location.href = window.location + "Main/AddMember";
    goto("Main/AddMember");
  };
  handleEdit = (id) => {
    //window.location.href = window.location + "Main/EditMember" + "/" + id;
    goto("Main/EditMember" + "/" + id);
  };

  render() {
    const { member, loading } = this.state;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />
          <Container fluid>
            <h6 className="activejobs">Users</h6>
            <div className="searchexp">
              <input
                type="text"
                id="search"
                className="loginsearchfa"
                placeholder=" Search Users"
                onChange={(e) => this.handleUserChange(e)}
              />
              <div className="btnaddblog" onClick={this.handleAdd}>
                <i class="fas fa-plus"></i>
              </div>
            </div>

            <div className=" cardtable">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className="containeruserscroll">
                    <table className="table table-hover  table-responsive  tablealot">
                      <thead>
                        <tr>
                          <th>Names</th>
                          <th>Phone number</th>
                          <th>Email</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {member &&
                          member
                            .filter((item) =>
                              item.firstName

                                ?.toLowerCase()
                                .includes(this.state.searchItem)
                            )
                            .sort((persone, perstwo) => {
                              if (
                                persone.firstName.toLowerCase() <
                                perstwo.firstName.toLowerCase()
                              )
                                return -1;
                              if (
                                persone.firstName.toLowerCase() >
                                perstwo.firstName.toLowerCase()
                              )
                                return 1;
                              return 0;
                            })
                            .map((items) => {
                              if (items.userStatus !== "Cancelled") {
                                return (
                                  <tr>
                                    <td className="userinfo ">
                                      {items.firstName} {items.middleName}{" "}
                                      {items.lastName}
                                    </td>
                                    <td className="userinfo ">
                                      {items.mobile}
                                    </td>
                                    <td className="userinfo ">{items.email}</td>
                                    <td className="userinfo ">
                                      <span
                                        className="viewdetclion "
                                        onClick={() =>
                                          this.handleEdit(items.userID)
                                        }
                                      >
                                        Edit
                                      </span>
                                      {/* <span className="viewdetclion ml-3">Remove</span> */}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </Container>
        </div>
        <br />
        <Footer />
      </div>
    );
  }
}

export default Members;
