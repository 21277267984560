import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../../Auth";
import Navbar from "../../../components/Navbar";
import SignOut from "../../SignOut";
import { link, goto, wordContains, numb2 } from "../../link.js";
import axios from "axios";
import Footer from "../../footer";
import Loading from "../../loading";
export class RoundInfo extends Component {
  constructor() {
    super();
    this.state = {
      round: [],
      roundid: "",
      searchItem: "",
      sums: [],
      loading: false,
      ytdtopay: 0,
      ytdpaid: 0,
      totalshares: 0,
      valueshares: 0,
      membershipnumber: 0,
      actualcapital: 0,
      capitalbalance: 0,
      commission: 0,
      commissionblance: 0,
    };
  }

  handleCreateRound = () => {
    //window.location.href = window.location + "Main/CreateRound";
    goto("Main/CreateRound");
  };
  handleEditRond = (roundid) => {
    //e.preventDefault();
    //window.location.href = window.location + "Main/EditRound/" + roundid;
    goto("Main/EditRound/" + roundid);
  };
  handleDetails = (id) => {
    //window.location.href = window.location + "Main/Memberdetails" + "/" + id;
    goto("Main/Memberdetails/" + id);
  };
  handleMigrateRond = (id) => {
    //window.location.href = window.location + "Main/AddMemberRound" + "/" + id;
    goto("Main/AddMemberRound/" + id);
  };
  handlememberRond = () => {
    // window.location.href =
    //   window.location + "Main/CreateMemberRound/" + this.state.roundid;
    goto("Main/CreateMemberRound/" + this.state.roundid);
  };
  handleContribution = () => {
    goto("Main/Contribution/" + this.state.roundid);
  };
  handleDistribution = () => {
    goto("Main/Distribution/" + this.state.roundid);
  };
  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    this.getRound();
  };

  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  getRound() {
    var token = localStorage.getItem("token");

    let path = window.location.hash;
    let roundId = path.split("/")[3] || "";

    var config = {
      method: "post",
      url:
        link() +
        `&do=getcontributions&ra=round&ft=rec&elmt=roundID&id=${roundId}`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      timeout: 30000
    };
    //this.setState({ });
    axios(config)
      .then((response) => {
        this.setState({
          round: response.data,
          roundid: response.data.roundID,
          loading: false,
        });

        let ytdtopay = 0;
        let ytdpaid = 0;
        let totalshares = 0;
        let valueshares = 0;
        let membershipnumber = 0;
        let actualcapital = 0;
        let capitalbalance = 0;
        let commission = 0;
        let commissionblance = 0;
        const sums = [];
        response.data.attachement &&
          response.data.attachement.map((member, indx) => {
            //for each member
            //console.log(member);
            valueshares =
            
              Number.parseFloat(valueshares) +
              Number.parseFloat(member.sharesValue);
            totalshares =
              Number.parseFloat(totalshares) +
              Number.parseFloat(member.numberOfShares);
              if(member.attachment.Contribution) {ytdtopay = ytdtopay + member.attachment.Contribution.capitaltopay;
                ytdpaid = ytdpaid + member.attachment.Contribution.capitalpaid;}
              

            membershipnumber =
              membershipnumber + Number.parseFloat(member.numberOfMemberships);
              if(member.attachment.Contribution) { actualcapital =
              actualcapital +
              Number.parseFloat(member.attachment.Capital.capitalpaid);
            capitalbalance =
              capitalbalance +
              Number.parseFloat(member.attachment.Capital.capitalbalance);}

            if (
              member.attachment.Commission &&
              member.attachment.Commission.capitaltopay
            ) {
              commission =
                commission +
                Number.parseFloat(member.attachment.Commission.capitaltopay);
            }

            if (
              member.attachment.Commission &&
              member.attachment.Commission.capitalbalance
            ) {
              commissionblance =
                commissionblance +
                Number.parseFloat(member.attachment.Commission.capitalbalance);
            }
          });
        //console.log(sums);
        this.setState({
          sums,
          ytdtopay,
          ytdpaid,
          totalshares,
          valueshares,
          membershipnumber,
          actualcapital,
          capitalbalance,
          commission,
          commissionblance,
        });

        //console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'ECONNABORTED') {
          alert("Server connection timed out");
      } else {
       // alert("Error occured");
      }
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { round, loading } = this.state;
    //console.log(round.attachement && round.attachement);
    var termcontribution = [];
    termcontribution =
      round.attachement &&
      round.attachement.map((member) => {
        return member.startCapital + member.capitalPaidAmount;
      });

    return (
      <div>
        <Navbar />
        <SignOut />
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="contentdashbord">
              <br />
              <Container fluid>
                <h6 className="activejobs">Round Information</h6>
                <div className="searchadd">
                  <button
                    className="btndistr "
                    onClick={this.handleContribution}
                  >
                    Contribution
                  </button>
                  <button
                    className="btndistr"
                    onClick={this.handleDistribution}
                  >
                    Round Payment
                  </button>
                  {/* <input
                type="text"
                id="search"
                className="loginsearchfa"
                placeholder=" Search a Round here…
                "
              />
              <div className="btnaddblog " onClick={this.handleCreateRound}>
                <i class="fas fa-plus"></i>
              </div> */}
                </div>
                <div className="activeuser">
                  <div class="row ">
                    <div class="col col-lg-4">
                      <div className=" conten">
                        Round Name:
                        <span className="unity">{round.name}</span>
                      </div>
                      <div className=" conten">
                        Start Date:
                        <span className="unity">
                          {" "}
                          {round.startDate
                            ? round.startDate.toString().substring(0, 10)
                            : ""}
                        </span>
                      </div>
                      <div className=" conten">
                        End Date:
                        <span className="unity">
                          {round.endDate
                            ? round.endDate.toString().substring(0, 10)
                            : ""}
                        </span>
                      </div>
                      <div className=" conten">
                        Number of Members:
                        <span className="unity">
                          {round.attachement && round.attachement.length}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div className=" conten">
                        Value of a Share:
                        <span className="unity">
                          {round.currency} {numb2(round.shareValue)}
                        </span>
                      </div>
                      <div className=" conten">
                        Total Shares:
                        <span className="unity">{this.state.totalshares}</span>
                      </div>
                      <div className=" conten">
                        Total Share Value:
                        <span className="unity">
                          {round.currency} {numb2(this.state.valueshares)}
                        </span>
                      </div>
                    </div>
                    <div class="col col-lg-4">
                      <div className=" conten">
                        Round Payment Shares:
                        <span className="unity">
                          {this.state.membershipnumber}
                        </span>
                      </div>
                      <div className=" conten">
                        Round Payment Share Value:
                        <span className="unity">
                          {round.currency}{" "}
                          {numb2(round.membershipContributionAmount)}
                        </span>
                      </div>
                      <div className="col conten">
                        Mothly Reserve Shares:
                        <span className="unity">{this.state.totalshares}</span>
                      </div>
                      <div className="col conten">
                        Value of Reserve Share:
                        <span className="unity">
                          {round.currency}{" "}
                          {numb2(round.savingContributionAmount)}
                        </span>
                      </div>
                      {/* <div className=" migrate ">
                    <p
                      className="viewdetclionedit ml-5 "
                      onClick={() => this.handleEditRond(round.roundID)}
                    >
                      Edit
                    </p>
                  </div> */}
                    </div>
                  </div>{" "}
                </div>

                <div className=" cardtable">
                  <div className="containeruserscroll">
                    <div className="membadd d-flex">
                      <p className=" contentmemb">Members</p>
                      {/* <p className="viewdetclionmemb ">View Details</p> */}
                      <input
                        type="text"
                        id="search"
                        className="loginsearchfaro"
                        placeholder=" Search a name..."
                        onChange={(e) => this.handleUserChange(e)}
                      />
                      <div
                        className="btnaddblogadds "
                        onClick={() => this.handleMigrateRond(round.roundID)}
                      >
                        <i class="fas fa-plus"></i>
                      </div>
                    </div>

                    <table className="table table-hover  table-responsive  tablealot">
                      <thead>
                        <tr>
                          <th className="userprony ">Names</th>
                          <th className="userprony ">Shares</th>
                          <th className="userprony ">Value of Shares</th>
                          <th className="userprony ">
                            Round Payment
                            <br />
                            Shares
                          </th>
                          <th className="userprony ">Reserve Shares</th>
                          <th className="userprony ">
                            Monthly
                            <br />
                            Contribution{" "}
                          </th>
                          <th className="userprony ">
                            Actual
                            <br /> Social Capital
                          </th>
                          <th className="userprony ">
                            Capital
                            <br />
                            Balance
                          </th>
                          <th className="userprony ">Commission</th>
                          <th className="userprony ">
                            {" "}
                            Commission
                            <br />
                            Balance
                          </th>
                          <th className="userprony "></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="userpronou "></td>
                          <td className="userpronou ">
                            {numb2(this.state.totalshares)}
                          </td>
                          <td className="userpronou ">
                            {numb2(this.state.valueshares)}
                          </td>
                          <td className="userpronou ">
                            {numb2(this.state.membershipnumber)}
                          </td>
                          <td className="userpronou ">
                            {numb2(this.state.totalshares)}
                          </td>
                          <td className="userpronou ">
                            {round.attachement &&
                              numb2(
                                round.savingContributionAmount *
                                  this.state.totalshares +
                                  round.membershipContributionAmount *
                                    this.state.membershipnumber
                              )}
                          </td>
                          <td className="userpronou ">
                            {round.attachement &&
                              numb2(this.state.actualcapital)}
                          </td>
                          <td className="userpronou ">
                            {round.attachement &&
                              numb2(this.state.capitalbalance)}
                          </td>
                          <td className="userpronou ">
                            {round.attachement && numb2(this.state.commission)}
                          </td>

                          <td className="userpronou ">
                            {round.attachement &&
                              numb2(this.state.commissionblance)}
                          </td>
                          <td className="d-flex"></td>
                        </tr>

                        {round.attachement &&
                          round.attachement
                            .filter((member) =>
                              member.firstName
                                ?.toLowerCase()
                                .includes(this.state.searchItem)
                            )
                            .map((member) => {
                              
                              return (
                                <tr>
                                  <td className="userpronyblue ">
                                    {member.attachment.names}
                                  </td>
                                  <td className="userpronyblue ">
                                    {member.numberOfShares}
                                  </td>
                                  <td className="userpronyblue ">
                                    {" "}
                                    {numb2(member.sharesValue)}
                                  </td>
                                  <td className="userinfo ">
                                    {" "}
                                    {member.numberOfMemberships}
                                  </td>
                                  <td className="userinfo ">
                                    {numb2(member.numberOfSavings)}
                                  </td>
                                  {/* {
                             item.map((item) => {
                                 return (
                                   item.savingTermAmount * item.numberOfSavings +
                                   item.membershipTermAmount * item.numberOfMemberships
                                 );
                               });
                          } */}
                                  <td className="userinfo ">
                                    {numb2(
                                      member.numberOfSavings *
                                      member.savingTermAmount +
                                      member.numberOfMemberships *
                                      member.membershipTermAmount
                                    )}
                                  </td>
                                  <td className="userinfo ">
                                    {numb2(
                                      member.attachment.Capital &&
                                      member.attachment.Capital.capitalpaid
                                    )}
                                  </td>
                                  <td className="userinfo ">
                                    {numb2(
                                      member.attachment.Capital &&
                                      member.attachment.Capital.capitalbalance
                                    )}
                                  </td>
                                  <td className="userinfo ">
                                    {numb2(member.commissionTermAmount)}
                                  </td>
                                  <td className="userinfo ">
                                    {numb2(
                                      member.attachment.Commission &&
                                      member.attachment.Commission
                                          .capitalbalance
                                    )}
                                  </td>

                                  <td className="userinfo ">
                                    <span
                                      className="viewdetclionedit mr-2"
                                      onClick={() =>
                                        this.handleDetails(
                                          member.memberID + "/member/memberround"
                                        )
                                      }
                                    >
                                      Details
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Container>
            </div>

            <br />
          </>
        )}
        <Footer />
      </div>
    );
  }
}

export default RoundInfo;
